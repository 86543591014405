<template>
<v-card color="grey lighten-4" min-width="450px" flat >
<v-toolbar color="primary" dark class="elevation-1" height="50px">
    <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
    <v-toolbar-title>Filtre</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon color="" @click.native="switchModal('modalFilter', 0)"><v-icon>mdi-close</v-icon></v-btn>
</v-toolbar>
    <v-card-text><v-layout row wrap>
        <v-flex xs12 class="pa-1">
            <v-text-field label="Titre" v-model="filterObj.fil_label" ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-btn block dark small color="success" @click="addFilter()">
                <v-icon dark>mdi-plus</v-icon><span class="hidden-sm-and-down"> Nouveau</span>
            </v-btn>
            <v-list>
                <v-list-item v-for="(filterText, index) in filterObj.fil_items" :key="index" >
                    <v-text-field label="Filtre" v-model="filterObj.fil_items[index]" @input="filteredLayer()"></v-text-field>
                    <v-btn fab dark small color="red" @click="deleteFilterItem(index)">
                        <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                </v-list-item>
            </v-list>

        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-list>
            <v-list-item v-for="(layObj, index) in items" :key="index" >
                <v-list-item-action>
                    <v-icon :color="(layObj.lra_color == '#ffffff') ? '#000000' : layObj.lra_color" style="cursor:pointer;">mdi-palette</v-icon>
                </v-list-item-action>
                <v-list-item-title v-if="layObj.lra_disable" style="text-decoration : line-through; color:grey; opacity:0.6;">{{ layObj.lra_source_id }}</v-list-item-title>
                <v-list-item-title v-else>{{ layObj.lra_source_id }}</v-list-item-title>
            </v-list-item>
            </v-list>
        </v-flex>
    </v-layout></v-card-text>
    <v-divider light></v-divider>
    <v-card-actions>
        <!-- <v-menu v-if="eveInfo.eve_id > 0" v-model="optionMenu" offset-y transition="slide-x-transition" bottom right >
            <v-btn slot="activator" outlined class="ml-0 pa-0" style="min-width: 5px;" color="grey"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            <v-btn class="ma-0" color="error" @click.native="deleteEvent()" :disabled="saveProgress">
                <v-icon left>mdi-trash-can</v-icon>Supprimer
            </v-btn>
        </v-menu> -->
        <v-spacer></v-spacer>
        <v-btn 
            v-if="(['admin', 'supervisor', 'manager'].indexOf(usr_role) != -1 )" 
            color="success" 
            :loading="saveProgress" @click.native="saveFilter()" :disabled="saveProgress">
            Enregistrer
        </v-btn>
        <v-btn outlined color="grey" @click="switchModal('modalFilter', 0)">
            Fermer
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheData } from "../store/cache.module"

export default {
    name: 'project_edit',
    props: ['toast', 'pro_id', 'filterInfo', 'switchModal'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            loading: { getLayer: false },
            locDateFormat: dateLocale, // Format de date
            state : this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            dateEve: { start: false, end: false },
            optionMenu: false,
            usr_role: this.user.usr_role,
            items: [],
            filterObj: { 
                fil_id: 0, fil_pro_id: this.pro_id, fil_sta_id: 'GEN01', fil_label: '', fil_items: [''], fil_code: '',
                _method: 'POST',
                _path: 'filter'
            }
        }
    },
    computed: {
        user(){
            return cacheData.user
        },
    },
    mounted:function (){
        if( this.filterInfo.fil_id !== undefined && this.filterInfo.fil_id > 0 ){
            this.filterObj.fil_id     = this.filterInfo.fil_id     
            this.filterObj.fil_pro_id = this.filterInfo.fil_pro_id 
            this.filterObj.fil_sta_id = this.filterInfo.fil_sta_id 
            this.filterObj.fil_label  = this.filterInfo.fil_label  
            this.filterObj.fil_items  = this.filterInfo.fil_items  
            this.filterObj.fil_code   = this.filterInfo.fil_code   
        }
        this.getLayer()
    },
    methods: {
        getLayer(){
            this.loading.getLayer = true
            this.$http.get( '/layersassoc/?prv_id=' . this.prv_id )
            .then( (response) => {
                if( Object.prototype.toString.call( response.data ) === '[object Array]' ) {
                    //response.data.forEach(item => {
                    //    item.pre_lra_tem_id = item.lra_tem_id
                    //    item.pre_lra_obt_id = item.lra_obt_id
                    //})
                    this.items = response.data
                    this.filteredLayer()
                }
                this.loading.getLayer = false
            })
        },
        addFilter(){
            this.filterObj.fil_items.push('')
        },
        filteredLayer() {
            this.items.forEach( (item) => {
                let letterMatch = false
                this.filterObj.fil_items.forEach( (label) => {
                    let isFound = -1
                    if( label.indexOf('*') == 0 ){
                        label = label.substr(1)
                        isFound = item.lra_source_id.toLowerCase().indexOf(label.toLowerCase())
                    } else {
                        isFound = item.lra_source_id.toLowerCase().indexOf(label.toLowerCase())
                        isFound = ( isFound == 0 ) ? isFound : -1
                    }
                    if( label.length > 0 && isFound > -1 ){
                        item.lra_disable = false
                        letterMatch      = true
                    } else if( !letterMatch && label.length > 0 ) {
                        item.lra_disable = true
                    }
                })
            })
            this.items.sort( (a) => {
                return a.lra_disable
            })
        },
        deleteFilterItem(index){
            this.filterObj.fil_items.splice(index,1);
            this.filteredLayer()
        },
        saveFilter(){
            this.toast({ color: 'success', text: 'copucoucoué !' })
            this.$http.post( '/filters/', this.filterObj )
            .then( () => {
                this.toast({ color: 'success', text: 'Filtre enregistré !' })
                this.switchModal('modalFilter', 0, true)
            }).catch(function () {
                this.toast({ text: 'Erreur lors de l\'enregistrement !' })
            });
        },
        cancelEdit(){
            console.log('fonction')
        },
        deleteEvent(){
            this.deleteObjEvent( () => {
                this.switchModal('modalFilter', 0, true)
            })
        }
    },
    components: { }
}
</script>