<template>
<div style="height: 100%; position: relative; overflow:hidden;">
    <div id="map-container" style="height: 100%;">
        <!-- <v-alert v-if="!user.usr_pro_id" icon="mdi-alert-decagram" :value="true" type="warning" style="margin:50px;">
        Vous devez selectionner un projet avant !
        </v-alert>
        <v-alert v-if="layerList.length == 0" icon="mdi-alert-decagram" :value="true" type="warning" style="margin:50px;">
        Vous devez associer des calques avant d'utiliser la carte.
        </v-alert> -->
    </div>
    <v-menu bottom left style="z-index:1050;">
        <template v-slot:activator="{ on }">
            <v-btn fixed dark fab bottom right color="orange" style="opacity: 0.80; top:80px; z-index:1050;" 
                elevation-1 v-on="on" title="Changer le fond de plan">
                  <v-icon>mdi-layers</v-icon>
            </v-btn>
        </template>
            <v-list dense>
                <template v-for="(item, pindex) in tileLayer">
                <v-list-item :key="pindex" @click="changeTileLayer(pindex)">
                  <v-list-item-action>
                      <v-icon :color="(activeTile == pindex) ? 'green' : 'orange'">{{(activeTile == pindex) ? 'mdi-check' : 'mdi-circle-outline'}}</v-icon>
                    </v-list-item-action>
                  <v-list-item-title>{{item.text}}</v-list-item-title>
                </v-list-item>
                </template>
            </v-list>
    </v-menu>
    <v-btn fixed dark fab bottom right :color="(addAlertState) ? 'red' : 'grey'" style="opacity: 0.80; top:150px; z-index:1040;" elevation-1 
        title="Ajouter une alerte" @click="activeAlert(!addAlertState)">
        <v-icon>mdi-alert</v-icon>
    </v-btn>
    <v-btn fixed dark fab bottom right :color="(showMeasure) ? 'primary' : 'grey'" style="opacity: 0.80; top:220px; z-index:1040;" elevation-1 
        title="Afficher les distances (objets)" @click="showMeasurement(!showMeasure)">
        <v-icon>mdi-map-marker-distance</v-icon>
    </v-btn>

    <div style="min-width:25%; position:absolute; left:90px; top:0px; z-index: 899;">
        <v-autocomplete
        v-model="searchAddress"
        label="Rechercher une adresse"
        :items="addressTab"
        item-value="id" item-text="label"
        :search-input.sync="search"
        @input="panTo"
        append-icon="mdi-search"
        ></v-autocomplete>
    </div>

    <v-btn v-if="!drawSaved" color="grey" style="right: 100px; bottom: 50px; z-index:1500; position:fixed;" dark absolute bottom right fab @click="drawSaved = true; disableDraw();">
        <v-icon>mdi-cancel</v-icon>
    </v-btn>
    <v-btn v-if="!drawSaved" color="success" style="bottom: 50px; z-index:1500; position:fixed" dark absolute bottom right fab @click="saveDrawJson()">
        <v-icon>mdi-content-save</v-icon>
    </v-btn>
    <v-btn small color="blue lighten-2" style="right:20px; bottom: 50px; z-index:1049; position:fixed" dark absolute bottom right @click="toolBar = !toolBar">
        <v-icon v-if="toolBar">mdi-chevron-double-down</v-icon>
        <v-icon v-if="!toolBar">mdi-chevron-double-up</v-icon>
    </v-btn>
    <!--  Outils (barre principale) -->
    <v-bottom-navigation v-if="toolMode == 0 && toolBar"  :value="true" fixed absolute color="light" :height="botNav.height" style="z-index:1050; position:fixed">
        <v-btn v-if="projection !== 'CRS'" text :color="(locatemeState) ? 'green' : 'grey'" @click="locateMe()" >
            <span>Me localiser</span><v-icon>mdi-crosshairs-gps</v-icon>
        </v-btn>
        <v-btn text :color="(!addSelectionState) ? 'grey' : 'blue'" @click="selectMultipleLayer()"><span>Selection</span><v-icon :color="(!addSelectionState) ? 'green' : 'blue'">mdi-select-marker</v-icon></v-btn>
        <v-btn text color="teal" @click="switchToolbar(2)" v-if="layerList.length && layerByVersion['VER02'].length"><span>Dessin</span><v-icon>mdi-pencil</v-icon></v-btn>
        <v-btn text color="teal" @click="switchToolbar(3)"><span>Calques</span><v-icon>mdi-layers</v-icon>
            <v-badge :color="(activeLayers == 0) ? 'red' : 'green'"><span slot="badge">{{ activeLayers }}</span></v-badge>
        </v-btn>
        <v-btn text color="teal" @click="switchToolbar(4)" v-if="layerList.length"><span>Arbre</span><v-icon>mdi-tree</v-icon></v-btn>
    </v-bottom-navigation>
    <!--  Alertes -->
    <v-bottom-navigation v-if="toolMode == 1" :value="toolBar" fixed absolute color="light" :height="botNav.height" style="z-index:1050; position:fixed;">
        <v-btn text color="primary" @click="switchToToolbar()"><span>retour</span><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-btn text color="primary" @click="displayAlert()"><span>Afficher/cacher</span><v-icon>mdi-eye-off</v-icon></v-btn>
        <v-btn text color="primary" @click="activeAlert(true)"><span>Nouvelle alerte</span><v-icon>mdi-map-marker-plus</v-icon></v-btn>
    </v-bottom-navigation>
    <!--  Dessin -->
    <v-bottom-navigation v-if="toolMode == 2" :value="toolBar" fixed absolute color="light" :height="botNav.height" style="z-index:1050; position:fixed;">
        <v-btn text color="primary" @click="switchToToolbar()"><span>retour</span><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-select prepend-icon="mdi-layers" solo style="display: inline-flex; width: 200px;" dense 
            v-model="lraObj.lra_source_id" :items="filteredLayerList"
            @change="setCurrentLayer" >
        </v-select>
    </v-bottom-navigation>
    <!--  Calques bottom Nav -->
    <v-bottom-navigation v-if="toolMode == 3" :value="toolBar" 
        class="bottnavdraw" fixed absolute color="light" :height="botNav.height" style="z-index:1050; position:fixed;">
        <v-btn text color="primary" @click="switchToToolbar()"><span>retour</span><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-menu v-model="layerMenu" :close-on-content-click="false" top offset-y :max-height="550" style="flex: initial;">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" text color="primary" class="pa-1"><span>Calques</span><v-icon>mdi-layers</v-icon></v-btn>
            </template>
            <v-list>
            <v-list-item v-for="(layObj, index) in objLayerList" :key="index" >
                <v-list-item-action @click="showHideLayer(index)">
                    <v-icon :color="(layObj.active) ? 'green' : 'gray'" style="cursor:pointer;">mdi-eye</v-icon>
                </v-list-item-action>
                <v-list-item-action @click="selectLayerColor(layObj.value)">
                    <v-icon :color="layObj.color" style="cursor:pointer;">mdi-palette</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ layObj.label }}</v-list-item-title>
            </v-list-item>
            </v-list>
        </v-menu>
        <v-menu v-model="versionMenu" :close-on-content-click="false" top offset-y :min-width="300" :max-height="500" style="flex: initial;">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" text color="primary" class="pa-1 ml-5"><span>Version</span><v-icon>mdi-folder-multiple</v-icon></v-btn>
            </template>
            <v-list>
            <v-list-item v-for="(verObj, index) in dxfVersion" :key="index" >
                <v-list-item-avatar @click="showHideVersion(index)">
                    <v-icon :color="(verObj.active) ? 'green' : 'gray'" style="cursor:pointer;">mdi-eye</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>#{{verObj.prv_id}} {{ verTab[ verObj.prv_typ_id ].label }}</v-list-item-title>
                <v-list-item-action >
                    <v-chip :color="(verTab[ verObj.prv_typ_id ].color !== undefined) ? verTab[ verObj.prv_typ_id ].color : 'orange'" small outlined label light>
                        {{verObj.prv_version}} 
                    </v-chip>
                </v-list-item-action>
            </v-list-item>
            </v-list>
        </v-menu>
    </v-bottom-navigation>
    <!--  Arbres -->
    <v-bottom-navigation v-if="toolMode == 4" :value="toolBar" fixed absolute color="light" :height="botNav.height" style="z-index:1050; position:fixed;">
        <v-btn text color="primary" @click="switchToToolbar()"><span>retour</span><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-btn :text="(!addTreeDrag)" :outlined="(addTreeDrag)" :color="(!addTreeDrag) ? 'grey' : 'green'" @click="switchToDraggableTree()">
            <span>Déplacer</span><v-icon :color="(!addTreeDrag) ? 'grey' : 'green'">mdi-drag</v-icon>
        </v-btn>
        <template  v-for="(item, index) in layerByVersion['VER04']">
            <v-btn :key="index" text :color="(!addTreeState) ? 'grey' : 'green'" @click="activeTree(item.lra_source_id)">
                <span>{{ item.lra_source_id }}</span><v-icon :color="(!addTreeState) ? 'grey' : 'green'">mdi-tree</v-icon>
            </v-btn>
        </template>
    </v-bottom-navigation>
    <!--  Réalisations -->
    <v-dialog v-if="madeDialog" v-model="madeDialog" max-width="600px">
    <v-card>
        <v-card-title class="headline grey lighten-2" primary-title >
          {{ layerActionLabel }}
        </v-card-title>
        <v-card-text>
            <v-autocomplete prepend-icon="mdi-layers" solo
                v-model="lraObj.lra_source_id" :items="allLayerList"
                @change="setCurrentLayer" >
            </v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="(lraObj.lra_source_id.length == 0) ? true : false" color="success" @click="copyObjectTo()">OK</v-btn>
            <v-btn outlined color="grey" @click="switchModal('madeDialog', 0)">Annuler</v-btn>
        </v-card-actions>
      </v-card>        
    </v-dialog>
    <!-- Selection de couleurs -->
    <v-dialog v-if="colorDialog" v-model="colorDialog" max-width="600px">
    <v-card>
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Choisir la couleur du calque :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="colorDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
            <v-color-picker v-model="layerColor" class="ma-2 elevation-0" hide-canvas :swatches="swatches" show-swatches></v-color-picker>
        </v-card-text>
        <v-card-actions>
            <v-btn color="" @click="saveLayerColor(layerColor)"><v-icon>mdi-check</v-icon>Ok</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
    <!-- Modification d'un filtre -->
    <v-dialog scrollable v-model="modalFilter" max-width="900" >
        <EditFilter class="elevation-2" v-if="modalFilter" :pro_id="pro_id" :filterInfo="filterInfo" :toast="toast" :switchModal="switchModal"></EditFilter>
    </v-dialog>
    <!-- Barre de chargement -->
    <v-dialog v-if="loading.getMap" hide-overlay v-model="loading.getMap" max-width="900" >
        <v-card-text style="background-color:white;">
            <v-progress-linear :indeterminate="true"></v-progress-linear>
        </v-card-text>
    </v-dialog>
    <!-- Selection d'arbre rapport -->
    <v-dialog v-if="selectionDialog" v-model="selectionDialog" max-width="600px">
    <v-card>
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Espèces selectionnées ({{ selectionItemsTotal }}) :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn  outlined rounded small color="white" 
                    v-if="Object.keys(selectionItems).length" @click="selectionDialog = false; switchModal('modalEvent', 1);"> <!-- :disabled="!routeState.edit" -->
                {{selectionEvent.title}}<v-icon>mdi-playlist-edit</v-icon>
            </v-btn>
            <v-btn icon color="" @click.native="selectionDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
            <v-list class="pb-0" v-if="Object.keys(selectionItems).length">
                <template  v-for="(item, index) in selectionItems">
                <v-list-item :key="index" class="pb-2">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.cdd_value }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <span class="caption mr-2">#{{ item.tre_id }}</span>
                        <v-chip small outlined label light color="light-green">
                            {{ item.qty }}
                        </v-chip>
                    </v-list-item-action>
                </v-list-item>
                </template>
                <v-divider></v-divider>
            </v-list>
            <v-alert v-else color="info" class="ma-2" icon="mdi-alert">Aucune selection</v-alert>
        </v-card-text>
    </v-card>
    </v-dialog>
    <v-dialog v-model="modalEvent" max-width="900" style="z-index:2066;">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>{{selectionEvent.title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <EventForm class="elevation-2" 
                v-if="modalEvent" :toast="toast" :switchModal="switchModal"
                :xxxId="selectionEvent.xxxId" :eveId="selectionEvent.eveId" 
                :eveTable="selectionEvent.eveTable" :eveType="selectionEvent.eveType" 
                :title="selectionEvent.title" :cooridnates="selectionEvent.cooridnates">
            </EventForm>
        </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
//import LeafGeom   from 'leaflet-geometryutil';
//import LeafMeasure  from 'leaflet-measure-path';
import LfMap        from '../plugins/map';
//import LfMiniMap    from '../plugin/Control.MiniMap';
import { v4 as uuidv4 } from 'uuid';
import EditFilter      from '@/components/Edit_filter.vue'
import EventForm  from '@/components/event/EventForm.vue'
import { cacheGetters, cacheData } from "../store/cache.module"
import { treeUpdateService, filterUpdateService } from '@/rxjsServices';
import { bus } from '@/plugins/bus'
var mapthis = { map: {} }

export default {
    name: 'maptool',
    props: ['toast', 'filterView'],
    components: { EditFilter, EventForm },
    data: function () {
        return {
            map: null,
            loading: { getVersion: false, getMap: false, filters: [] },
            alertDialog: false,
            madeDialog: false, colorDialog: false,
            attribDialog: false,
            botNav: { height: 55 },
            layerMenu: false,
            filterMenu: false,
            versionMenu: false,
            blockMenu: false, blockMenuGen: false,
            popup: false, 
            dealIcon: false,
            dealTab: [],
            deal: {},
            drawEdit: false, dialogObj:false, dialogLayer: false, modalEvent: false,
            selectionDialog: false,
            selectionItems: [],
            selectionItemsTotal: 0,
            selectionEvent: { xxxId: [], eveId:0, eveTable: "cad", eveType: "ACT", title:"Actions", cooridnates:null },
            dxfObject: {},
            selectList : this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            state      : this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            objectList : {},
            lraObj: { lra_tem_id:1, lra_obt_id:1, lra_source_id: '', color: '#000000' },
            layerList: [], allLayerList: [], objLayerList: {},
            dxfLayer: [], dxfBlocks: {}, genBlocks: {}, dxfVersion: [],
            letterDisplay: 0,
            drawSaved: true,
            projection: 'EPSG3857',
            toolBar: 1,
            toolMode: 0,
            drawState: { editMode: false, dragMode: false, rotation:0 },
            alertObj : {
                alt_id : 0, alt_lat : 0, alt_lng : 0
            },
            loadAlert: false,
            verTab: {},
            lraColor: { lra_id:0, lra_color:'', lra_source_id:'' },
            tabVersion: {},
            layerAction: 'copy',
            cardVersionType: '',
            filters: [],
            pro_id: 0, //this.user.usr_pro_id,
            modalFilter: false,
            filterInfo: {},
            layerSource: true,
            activeTile: 'osm',
            tileLayer: {
                osm : { text: 'Fond de plan OSM',   link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/arcgistiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                ign : { text: 'Fond de plan IGN',   link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/osmtiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                bph : { text: 'Fond de plan photo', link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/igntiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                bwt : { text: 'Sans fond',          link: '', color: 'green', icon: 'mdi-check'},
            },
            addSelectionState: false,
            addMarkerState: false,
            addTreeState: false,
            addAlertState: false,
            addTreeDrag: false,
            locatemeState: false,
            searchAddress: null,
            addressTab: [],
            search: null,
            searchWord: '',
            timeOutHandle: 'off',
            filter_table: {}, //this.user.filter_table_id //this.$auth.userPrefuser.filter_table_id,
            current_prv_id: 0,
            swatches: [
                ['#FF0000', '#AA0000', '#550000'], ['#FFFF00', '#AAAA00', '#555500'], ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'], ['#0000FF', '#0000AA', '#000055'],
            ],
            layerColor: '',
            layerByVersion: { 'VER02': [], 'VER04': [] },
            globalFilters: {},
            showMeasure: false
        }
    },
    watch: {
        search (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' ){
                this.timeOutHandle = 'on'
                this.searchTempo = setTimeout( () => {
                    this.loadAddress(this.searchWord)
                    this.timeOutHandle = 'off'
                }, 1000)
            }
        },
    },
    computed: {
        user(){
            return cacheData.user
        },
        layerActionLabel: function () {
            if( this.layerAction == 'copy' ){
                return 'Copier l\'objet vers :'
            } else {
                return 'Déplacer l\'objet vers :'
            }
        },
        activeLayers: function(){
            var totalActive = 0
            for( var prop in this.objLayerList ){
                if( this.objLayerList[prop].active ){
                    totalActive += 1
                }
            }
            return totalActive
        },
        filteredLayerList(){
            return this.layerList.filter(item => item.prv_typ_id === 'VER02')
        }
    },
    created(){
        this.subscribeTree = treeUpdateService.getTree().subscribe(message => {
            if (message) {
                mapthis.map.removeTreeIcon(message.obj.tre_uid)
            }
        })
        this.subscribeFilter = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                //mapthis.map.removeAllLayer()
                this.globalFilters = cacheGetters.getFilter()
                this.initMap()
            }
        })
    },
    beforeMount() {
        this.globalFilters = cacheGetters.getFilter()
        this.filter_table  = this.user.filter_table_id
        this.pro_id        = this.user.usr_pro_id
        this.projection    = ( cacheData.pro_projection.length ) ? cacheData.pro_projection.length : this.projection

        this.selectList.objectType.forEach(obt => {
            if( this.objectList[ 'tem' + obt.parent_id ] === undefined ){
                this.objectList[ 'tem' + obt.parent_id ] = []
            }
            this.objectList[ 'tem' + obt.parent_id ].push(obt)
        })
        // Version
        this.state.ver = this.getCacheVersion()
        if( Object.prototype.toString.call( this.state.ver ) === '[object Array]'  ){
            this.state.ver.map( (elem) => {
                this.verTab[ elem.prv_typ_id ] = elem
            })
        }
    },
    mounted() {
        mapthis.map = new LfMap('map-container')
        this.drawState                   = mapthis.map.drawState  // pour la rotation
        bus.$on('alert:open', (obj) => this.viewAlert(obj))
        bus.$on('card:open', (obj, leaflet_id, versionType) => this.showObjectCard(obj, leaflet_id, versionType))
        bus.$on('uiobject:set', (obg, state) => this.setObjState(obg, state))
        bus.$on('selectedlist:open', (obj) => this.showSelectionCard(obj))
        bus.$on('tree:delete', (obj) => this.deleteTree(obj))
        bus.$on('tree:update', (obj) => this.updateTreeCoordinate(obj))
        mapthis.map.dayjs = this.$date
        if( this.$route.params.latlng !== undefined && this.$route.params.latlng.length > 0 ){
            mapthis.map.mapCenter = this.$route.params.latlng.split(',')
        }
        //if( this.user.usr_pro_id ){        
            this.displayMap()
            this.initMap()
        //}
        this.getVersion()
        setTimeout( () => {
            this.displayAlert()
        }, 2000)
    },
    methods:{
        async initMap(){
            this.layerByVersion = { 'VER02': [], 'VER04': [] }
            this.objLayerList = {}
            this.allLayerList = []
            this.layerList = []
            this.lraObj = { lra_tem_id:1, lra_obt_id:1, lra_source_id: '', color: '#000000' }
            await mapthis.map.removeDxfLayer()
            await this.getLayerAssoc()
            this.getLayer()
            
        },
        displayMap() {
            if( cacheData.pro_projection == 'CRS' ){
                this.projection = 'Simple'
            }
            let project = cacheGetters.getCurrentProject()

            mapthis.map.setDrawSave = this.setDrawSave
            if( project.pro_lat ){
                mapthis.map.display( [project.pro_lat, project.pro_lng], 14, this.projection, this.lraObj, this.drawSaved, this.toast )
            } else {
                mapthis.map.display( [0, 0], 2, this.projection, this.lraObj, this.drawSaved, this.toast )
            }
        },
        closePopup(){
            this.dealIcon = false
            mapthis.map.clearDealMarker()
        },
        countLetter(){
            this.letterDisplay += 1
        },
        async getLayer(){
            await mapthis.map.removeTreeIcon('all')
            await mapthis.map.removeDxfLayer()
            this.$http.get( '/projectversions' ).then( (response) => { //, { prv_pro_id: this.$auth.userPref.pro_id }
                // Verification des versions à jour // Todoo
//                let versions = this.getCacheType('VER')
//                if( versions.length ){
//                    versions.forEach( elem => {
//                        if( elem.prv_pro_id !== cacheGetters.user.pro_id ){
//                            this.tabVersion[ elem.prv_typ_id ] = 'DL'
//                        } else {
//                            this.tabVersion[ elem.prv_typ_id ] = elem.prv_version
//                        }
//                    })
//                }
                this.displayVersion( response.data.data, [] )
            })
            .catch( (error) => {
                console.log(error)
                //let versions = this.getCacheType('VER')
                //if( ns.localStorage.isSet( APP_COD + '_vers' ) ){
                //    var response = ns.localStorage.get( APP_COD + '_vers' )
                //    this.displayVersion( response, this.tabVersion )
                //}
            })
        },
        displayVersion( versions ){
            let params = ''
            versions.forEach(version => {
                params   = '';
                if( this.globalFilters && version.prv_typ_id == 'VER04' && version.prv_typ_id_storage == 'PVS02' ){
                    if( Object.prototype.toString.call( this.globalFilters.eve_sta_id ) === '[object Array]' && this.globalFilters.eve_sta_id.length > 0 ){
                        params = '?extended=withcard,withcarddata,witheventstate&eve_sta_id=' + this.globalFilters.eve_sta_id.join(',')
                    }
                    if( Object.prototype.toString.call( this.globalFilters.eve_typ_id ) === '[object Array]' && this.globalFilters.eve_typ_id.length > 0 ){
                        let type = '&eve_typ_id=' + this.globalFilters.eve_typ_id.join(',') //order_by=eve_date_start&order_direction=desc&
                        params = (params.length > 0) ? params + type : '?extended=withcard,withcarddata,witheventstate' + type
                    }
                    let lstId = []
                    for( var prop in this.globalFilters ){
                        if( Object.prototype.toString.call( this.globalFilters[prop] ) === '[object Array]' && prop.substring(0, 10) == 'cdd_lsd_id' ){
                            lstId = lstId.concat( this.globalFilters[prop] )
                        }
                    }
                    if( lstId.length > 0 ){
                        params = (params.length > 0) ? params + '&cdd_lsd_id=' + lstId.join(',') : '?extended=withcard,withcarddata&cdd_lsd_id=' + lstId.join(',')
                    }

                }
                this.$http.get( '/projectversions/' + version.prv_id + '/geojson/' + params ).then( (response) => {
                    if( response.data !== undefined && response.data.type == 'FeatureCollection' ){
                        mapthis.map.displayGeojson(response.data, version.prv_typ_id, version.prv_id )
                    }
                })
            })
        },
        displayAlert(){
            this.loadAlert = !this.loadAlert
            if( this.loadAlert ){
                this.$http.get( '/events/?eve_sta_id=GEN01&eve_typ_id=%ALT%' )
                .then( (response) => {
                    var kp, vp
                    for (kp = 0; (vp = response.data.data[kp]) !== undefined; kp++) {
                        if( vp.eve_lat && vp.eve_lng ){
                            mapthis.map.addAlert( vp )
                        }
                    }
                })
            } else {
                mapthis.map.removeAllAlert()
            }
        },
        closeAlert(){
            this.alertDialog = false
        },
        showObjectCard( obj, leaflet_id, versionType ){
            this.cardVersionType = versionType
            obj.size = Math.round( obj.size ) || 0
            obj.area = Math.round( obj.area ) || 0
            this.attribDialog = false
            this.dxfObject = obj
            // ID Leaflet pour la copie d'objet
            if( leaflet_id !== undefined ){
                this.dxfObject.leaflet_id = leaflet_id
            } else {
                this.dxfObject.leaflet_id = 0
            }
            //this.dialogObj = true
            this.$router.push({ path: '/map/sheet/' + obj.uid + '/layer/' + obj.lra_id + '/type/' + obj.typ_id })
        },
        switchModal(name, mode){
            name    = name || 'dialogObj'
            mode    = mode || false
            //refresh = refresh || false
            this[name] = mode
            if( name == 'modalEvent' && !mode ){
                mapthis.map.removeAllAlert()
                this.loadAlert = false
                this.displayAlert()
            }
        },
        getLayerAssoc(){
            return new Promise((resolve, reject) => {
                this.$http.get( '/layersassoc/?extended=withversion&per_page=false&order_by=lra_sta_id_type&order_direction=asc' ).then( (response) => {
                    var items    = []
                    var allItems = []
                    var objLayerList = {}
                    var isActive = true
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        response.data.data.forEach(item => {
                            item.lra_color = ( item.lra_color == '#ffffff' ) ? '#000000' : item.lra_color
                            isActive = true
                            if( cacheData.filter_layer[item.lra_source_id] !== undefined ){
                                isActive = cacheData.filter_layer[item.lra_source_id]
                            }
                            if( item.lra_obt_id !== null && item.lra_obt_id !== undefined ){
                                items.push( {
                                    lar_id: item.lra_id,
                                    typ: 'lay', id: item.lra_source_id, label: item.lra_source_id, value: item.lra_source_id, 
                                    text: item.lra_source_id, filta: '', color: item.lra_color, staIdType : item.lra_sta_id_type, 
                                    active: isActive, prv_id: item.lra_prv_id, prv_typ_id: item.prv_typ_id
                                })
                            }
                            allItems.push( {
                                lar_id: item.lra_id,
                                typ: 'lay', id: item.lra_source_id, label: item.lra_source_id, value: item.lra_source_id, 
                                text: item.lra_source_id, filta: '', color: item.lra_color, staIdType : item.lra_sta_id_type, 
                                active: isActive, prv_id: item.lra_prv_id, prv_typ_id: item.prv_typ_id
                            })
                            objLayerList[item.lra_source_id] = {
                                lar_id: item.lra_id,
                                typ: 'lay', id: item.lra_source_id, label: item.lra_source_id, value: item.lra_source_id, 
                                text: item.lra_source_id, filta: '', color: item.lra_color, staIdType : item.lra_sta_id_type, 
                                active: isActive, prv_id: item.lra_prv_id,
                                lra_id: item.lra_id, prv_typ_id: item.prv_typ_id
                            }
                            if( this.layerByVersion[item.prv_typ_id] == undefined ){
                                this.layerByVersion[item.prv_typ_id] = []
                            }
                            this.layerByVersion[item.prv_typ_id].push(item)
                            mapthis.map.addLayerGroup('layerDxf', item.lra_source_id)
                            // Version Toddo (revoir)
                            if( mapthis.map.layerVersion['VER03'] == undefined ){
                                mapthis.map.layerVersion['VER03'] = {}
                            }
                            mapthis.map.layerVersion['VER03'][item.lra_source_id] = true
                        })
                        this.objLayerList         = objLayerList
                        this.allLayerList         = allItems // Tous les layers
                        this.layerList            = items    // Layer associés
                        this.lraObj.lra_source_id = this.layerList[0].id
                        mapthis.map.currentLayer  = this.layerList[0].id
                        mapthis.map.layerTable    = this.objLayerList
                    }
                    resolve()
                }).catch( () => {
                    reject()
                })
            })
        },
        setDrawSave( state ){
            this.drawSaved     = state
            mapthis.map.drawSaved = state
        },
        async saveDrawJson(){
            this.disableDraw() // ns.localStorage.set( APP_COD + '_plan_VER02', geoJson )
            if( this.objLayerList[mapthis.map.currentLayer].prv_typ_id == 'VER02' ){
                let geoJson = mapthis.map.getlayerDraw()
                let postData    = {
                    prv_id: this.objLayerList[mapthis.map.currentLayer],
                    prv_typ_id: 'VER02',
                    geojson: geoJson
                }
                this.$http.put( '/projectversions/' + this.objLayerList[mapthis.map.currentLayer].prv_id, postData ).then( (response) => {
                    this.setDrawSave(true)
                    if( response.data.data.prv_id > 0 ){
                        mapthis.map.layerDrawId = response.data.data.prv_id
                    }
                    this.tabVersion[ 'VER02' ] = response.data.data.prv_version
                    this.toast({ 
                        color: 'success', top:true, bottom:false, right:true, left:false, 
                        text: 'Modifications enregistrée ! Vous pouvez ajouter des infomations.' 
                    })
                })
            } else if( this.objLayerList[mapthis.map.currentLayer].prv_typ_id == 'VER04' ){
                let version = {
                    prv_typ_id :this.objLayerList[mapthis.map.currentLayer].prv_typ_id,
                    prv_id: this.objLayerList[mapthis.map.currentLayer].prv_id,
                    layer: mapthis.map.currentLayer
                }
                let trees = mapthis.map.getTreeDraw()
                for (const element of trees) {
                    let uuid = uuidv4()
                    await this.postTree(
                        { 
                            tre_uid: uuid, tre_lra_id: this.objLayerList[mapthis.map.currentLayer].lra_id, 
                            tre_acad_id: null, tre_sta_id: 'GEN01', tre_typ_id: 'TRE01', tre_lat: element.lat, tre_lng: element.lng,
                            tre_ext_id: uuid
                        },
                        version
                    )
                }
                this.activeTree('')
            }
        },
        postTree(tree, version){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/trees/', tree ).then( () => {
                    let treeToAdd = {
                        "type":"FeatureCollection",
                        "crs":{"type":"name","properties":{"name":"urn:ogc:def:crs:OGC:1.3:CRS84"}},
                        "bounds":{"northEast":[2.0710321820021433,49.44022478500005],"southWest":[2.0748494828830344,49.442732610409486]},
                        "layers":[],
                        "blocks":[],
                        "features":[
                            {
                                "type":"Feature","properties":{
                                    "uid": tree.tre_uid, "lra_id": tree.tre_lra_id,"typ_id":"SHA02","layer": version.layer,"handle":"1",
                                    "ownerHandle":"1F","color":"#8E43AD","formType":"POINT","size":0,"area":0,"leaflet_id":1
                                },
                                "geometry":{"type":"Point","coordinates":[tree.tre_lng, tree.tre_lat]}
                            },
                        ]
                    }
                    mapthis.map.mapCenter = [1, 1]
                    //this.addTreeState = false
                    //this.drawSaved    = true
                    //this.disableDraw()
                    mapthis.map.displayGeojson(treeToAdd, version.prv_typ_id, version.prv_id )
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        disableDraw(){
            mapthis.map.disableDraw()
        },
        viewAlert( altObj ){
            this.selectionEvent.xxxId    = this.pro_id
            this.selectionEvent.eveId    = altObj.eve_id
            this.selectionEvent.eveTable = 'pro'
            this.selectionEvent.eveType  = "ALT"
            this.selectionEvent.title    = 'Alerte'
            this.selectionEvent.cooridnates    = { lat: altObj.eve_lat, lng: altObj.eve_lng }
            //this.alertDialog = true
            this.switchModal('modalEvent', 1)
        },
        locateMe(){
            this.locatemeState = !this.locatemeState
            mapthis.map.locateMe(this.addMarkerState, this.locatemeState)
        },
        switchToToolbar(){
            if( this.toolMode == 2 ){
                mapthis.map.removePmControl()
                mapthis.map.setActiveLayer()
                mapthis.map.drawSaved = true // tooDoo
            }
            mapthis.map.activeAlert(false) // annuler insertion alerte
            mapthis.map.insertBlock(false) // annuler insertion de block
            mapthis.map.drawState.active   = false
            mapthis.map.drawState.dragMode = false
            mapthis.map.drawState.editMode = false
            this.toolMode     = 0
            //this.map.removePmControl()
        },
        switchToolbar(id){
            this.toolMode = id
            if( id == 3 ){     // Calques
                if( this.dxfLayer.length == 0 ){
                    this.dxfLayer    = this.allLayerList  //this.dxfLayer    = this.map.getLayersGroup()
                }
            }
            if( id == 2 ){     // Desssin
                mapthis.map.addPmControl()
                this.toolMode  = 2
                this.layerList.forEach(element => {
                    if( element.prv_typ_id == 'VER02' ){
                        this.lraObj.lra_source_id = element.id
                        mapthis.map.currentLayer  = element.id
                    }
                })
                if( Object.keys(this.dxfBlocks).length == 0 ){
                    this.dxfBlocks = mapthis.map.getBlocks('origin')
                    this.genBlocks = mapthis.map.getBlocks('generic')
                }
            }
        },
        showHideLayer(id, active){
            this.objLayerList[id].active = !this.objLayerList[id].active
            if( active !== undefined ){
                this.objLayerList[id].active = active
            }
            mapthis.map.showHideLayer( this.objLayerList[id].label, this.objLayerList[id].active )
        },
        showHideVersion(id){
            this.dxfVersion[id].active = !this.dxfVersion[id].active
            if( this.dxfVersion[id].prv_typ_id == 'VER03' ){
                for (var item in this.objLayerList) {
                    this.objLayerList[item].active = this.dxfVersion[id].active
                }
            }
            mapthis.map.showHideVersion( this.dxfVersion[id].prv_typ_id, this.dxfVersion[id].active )
            //for(var prop in this.objLayerList){
            //    if( this.objLayerList[prop].staIdType == 'LAY01' ){
            //        this.objLayerList[prop].active = this.dxfVersion[id].active
            //        mapthis.map.showHideLayer( this.objLayerList[prop].label, this.dxfVersion[id].active )
            //    }
            //}
        },
        makeRotation(){
            mapthis.map.addRotation(this.drawState.rotation)
        },
        setCurrentLayer(id){
            var lastLayerId          = mapthis.map.currentLayer
            mapthis.map.currentLayer = id
            if( mapthis.map.drawState.active && mapthis.map.drawState.layerId > 0 ){
                mapthis.map.switchObjectToLayer( mapthis.map.drawState.layerId );
                this.toast({ 
                    color: 'success', top:true, bottom:false, right:true, left:false, 
                    text: 'Objet déplacé de [' + lastLayerId + '] vers ' + mapthis.map.currentLayer
                })
            }
        },
        getVersion(){
            this.loading.getVersion = true
            this.$http.get( '/projectversions/?per_page=false&order_by=prv_typ_id&order_direction=asc')
            .then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    response.data.data.forEach(item => {
                        item.active = true
                    })
                    this.dxfVersion = response.data.data
                }
                this.loading.getVersion = false
            })
        },
        copyObjectTo(){  // copier un objet dans un calque
            if( this.layerAction == 'copy' ){
                mapthis.map.cloneObjectToLayer( this.dxfObject.leaflet_id, this.objLayerList[mapthis.map.currentLayer].color ) //, [mapthis.map.currentLayer]
            } else {
                mapthis.map.moveObjectToLayer( this.dxfObject.leaflet_id, this.objLayerList[mapthis.map.currentLayer].color )
            }
            this.saveDrawJson()
            this.switchModal('madeDialog', 0)
        },
        saveAttrib(){
            //this.map.saveAttrib(this.dxfObject)
            this.saveDrawJson()
        },
        activeAlert(state){
            this.addAlertState = state
            mapthis.map.activeAlert(state)
            //mapthis.map.addAlertMarker(state)
        },
        showMeasurement(state){
            this.showMeasure = state
            mapthis.map.showMeasures(state)
            //mapthis.map.addAlertMarker(state)
        },
        activeTree(lra_source_id){
            if( !this.addTreeState ){
                this.setCurrentLayer(lra_source_id)
                //mapthis.map.startCreateMarker()
                this.addTreeState = true
                mapthis.map.activeTree(this.addTreeState)
            } else {
                mapthis.map.getTreeDraw()
                mapthis.map.disableDraw()
                this.drawSaved    = true
                mapthis.map.drawSaved = true
                this.addTreeState = false
                mapthis.map.activeTree(this.addTreeState)
            }
        },
        activePasteInfo(state){
            mapthis.map.activePasteForm(state)
            //if( state ){
            //    this.pasteLayerId = dxfObject.leaflet_id
            //}
        },
        insertBlock( type, name ){
            mapthis.map.insertBlock( type, name )
        },
        selectLayerColor(lra_source_id){
            this.colorDialog            = true
            this.lraColor.lra_id        = this.objLayerList[lra_source_id].lra_id
            this.lraColor.lra_source_id = lra_source_id
        },
        saveLayerColor(color){
            this.$http.put( '/layersassoc/' + this.lraColor.lra_id , { lra_color:color, lra_id: this.lraColor.lra_id } )
            .then( () => {
                this.toast({ 
                    color: 'success', top:true, bottom:false, right:true, left:false, 
                    text: 'Couleur modifiée'
                })
            })
            this.objLayerList[this.lraColor.lra_source_id].color = color
            this.colorDialog = false
            mapthis.map.setLayerColor( this.lraColor.lra_source_id, color )
        },
        openFilter(index){
            this.filterInfo     = this.filters[index]
            this.switchModal('modalFilter', 1)
        },
        setObjState(obg, state){
            this.loading[obg] = state
        },
        changeTileLayer(id){
            this.activeTile = id
            mapthis.map.setTileLayer(this.tileLayer[id].link)
        },
        selectMultipleLayer(){
            if( !this.addSelectionState ){
                mapthis.map.addPmControl('select')
                this.addSelectionState = true
             } else {
                mapthis.map.removePmControl('select')
                this.addSelectionState = false
            }
        },
        showSelectionCard(layer){
            this.selectionEvent.xxxId    = []
            this.selectionEvent.eveId    = 0
            this.selectionEvent.eveTable = 'cad'
            this.selectionEvent.eveType  = "ACT"
            this.selectionEvent.title    = 'Actions'
            this.selectionEvent.cooridnates = null

            this.$http.post( '/trees/bbox/', { tree_bbox: layer }).then( (response) => {
                this.selectionEvent.xxxId = []
                this.selectionItemsTotal  = 0
                let trees = {}
                response.data.forEach(element => {
                    if( trees[ element.cdd_value ] === undefined ){
                        trees[ element.cdd_value ] = element
                        trees[ element.cdd_value ].qty = 1
                    } else {
                        trees[ element.cdd_value ].qty = trees[ element.cdd_value ].qty + 1
                    }
                    if( element.cad_id > 0 ){
                        this.selectionEvent.xxxId.push( element.cad_id )
                    }
                });
                this.selectionItems  = trees
                for( let prop in this.selectionItems){
                    this.selectionItemsTotal += parseInt(this.selectionItems[prop].qty)
                }
                this.selectionDialog = true
            })
        },
        panTo(){
            if( this.searchAddress !== null && this.searchAddress !== undefined ){
                var coor = this.addressTab[ this.searchAddress ].item.geometry.coordinates
                mapthis.map.panTo( coor )
                mapthis.map.addCircleZone( coor )
            }
        },
        loadAddress (val) {
            if( val.length > 1 ){
                this.addressTab = []
                let param = (process.env.VUE_APP_BANO_PARAM && process.env.VUE_APP_BANO_PARAM.length) ? process.env.VUE_APP_BANO_PARAM : ''
                this.$http.customRequest( {
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + val + param
                })
                .then( (response) => {
                    let data = []
                    let address, key
                    for (key = 0; (address = response.data.features[key]) !== undefined; key++) {
                        data[key] = { label: address.properties.label, value: address.properties.id, id:key, item: address }
                    }
                    this.addressTab = data
                })
            } else {
                return false
            }
        },
        deleteTree(tre_uid){
            this.$root.$confirm('Supprimer cet arbre', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm && tre_uid.length > 3 ){
                    this.$http.delete( '/trees/' + tre_uid ).then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Abre supprimée' })
                    }).catch( (error) => {
                        this.toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression !\n' + error })
                    })
                }
            })
        },
        switchToDraggableTree(){
            this.addTreeDrag = !this.addTreeDrag
            mapthis.map.draggableTreeIcon(this.addTreeDrag)
        },
        updateTreeCoordinate(tree){
            this.$http.put( '/trees/' + tree.tre_uid, tree ).then( () => {
                this.toast({ 
                    color: 'success', top:true, bottom:false, right:true, left:false, 
                    text: 'Modifications enregistrée !' 
                })
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates,
        getCacheVersion: cacheGetters.getVersion,
    },
    beforeDestroy() {
        bus.$off()
        this.subscribeTree.unsubscribe()
        this.subscribeFilter.unsubscribe()

        var layersState = {}
        for (var item in this.objLayerList) {
            layersState[item] = this.objLayerList[item].active
        }
        cacheData.filter_layer = layersState
        //this.$auth.savePref()
    }
}
</script>

<style lang="scss">
@import "../../node_modules/leaflet/dist/leaflet.css";
@import '../assets/leaflet.css';
@import '../assets/leaflet.pm.css';
@import "../../node_modules/leaflet.markercluster/dist/MarkerCluster.css";
@import "../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";

.dealInfo {
  position: absolute;
  top: 5px;
  right:5px;
  background-color: white;
  z-index: 500;
  max-width: 250px;
  max-width: 300px;
  max-height: 80%;
}
.leaflet-pane {
    z-index: 0;
}
.v-overlay--active{
    z-index: 1053 !important;
}
.v-dialog__content--active {
    z-index: 1055 !important;
}
.bottnavdraw {
    position: fixed;
}
.v-btn.active .v-icon {
    transform: rotate(-45deg);
}
.leaflet-measure-path-measurement {
    font-size: 15px;
}
</style>