import L    from 'leaflet';
// eslint-disable-next-line no-unused-vars
import LeafleatPm from 'leaflet.pm';
// eslint-disable-next-line no-unused-vars
import LfTouchHelp  from '../plugins/map_touch_help';
// eslint-disable-next-line no-unused-vars
import LeafleatMeasure from 'leaflet-measure-path';
// eslint-disable-next-line no-unused-vars
import MarkerCluster from 'leaflet.markercluster'
import turfRotate      from '@turf/transform-rotate'
//import turfCentroid    from '@turf/centroid'
import turfFlip        from '@turf/flip'
import turfArea        from '@turf/area'
import turfLength      from '@turf/length'
//import turfDestination from '@turf/destination'
//import turfScale       from '@turf/transform-scale'

import { bus } from '@/plugins/bus'

import { v4 as uuidv4 } from 'uuid';
class LfMap {
    constructor(mapContainer) {
      this.layerUrl = 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';
      this.mapContainer = mapContainer;
      this.baseLayerMap = this.setLayer('base'); //this.offlineLayerMap = this.setLayer('offline');
      this.Map          = null;
      this.arrayOfLatLngs = [];
      this.leaflet       = window.L;
      this.defautLatLng  = [47.47479783478821, 1.0530929565429689];
      this.markerLoc     = [0, 0];
      this.markerDrag    = null;
      this.circleMarker  = null;
      this.partMarkers   = L.layerGroup();
      this.svgIcon       = {
        green: '<div class="marker-svg marker-green"><center></center></div>',
        blue: '<div class="marker-svg marker-blue"><center></center></div>',
        orange: '<div class="marker-svg marker-orange"><center></center></div>',
        red: '<div class="marker-svg marker-red"><center></center></div>',
        truck: '<div class="marker-svg marker-truck"><center></center></div>',
        tree: '<div class="marker-tree marker-svg"></div>'
      };
      /************* LAYERS *************/
      this.baseLayer     = {};
      this.layerDraw     = L.layerGroup();
      this.layerDxf      = {}; // Tab of LayerGroup
      this.layerAlert    = L.layerGroup();
      this.layerTree     = L.layerGroup();
      this.layerAttrib   = L.layerGroup();
      this.layerVersion  = {}; // Tab of name LayerVersion
      this.layerTable    = {}; // Tab of name LayerVersion
      /************* BLOCKS *************/
      this.blocks        = { origin: {}, generic: {}, default: {} }
      /************* ******* *************/
      this.objAction     = function(){ alert('Action non paramétré') };
      this.handle        = 0;
      this.ownerHandle   = '1F';
      this.currentLayer  = '';
      this.shapeToDxf    = {  };
      this.layerDrawId   = 0;
      this.setDrawSave   = function(){ return false; };
      this.drawSaved     = true; // Todoo other way
      this.formTab       = {
        Polygon: 'LWPOLYLINE', LineString: 'LINE', Circle: 'CIRCLE', Arc: 'ARC',
        Point: 'POINT', Text: 'TEXT', Mtext: 'MTEXT', Insert: 'INSERT',
        Poly: 'LWPOLYLINE', Rectangle: 'LWPOLYLINE', Line: 'LINE', Marker: 'POINT'
      }; // Association des formes leaflet en DXF
      this.typTab       = {
        Polygon: 'SHA01', LineString: 'SHA01', Circle: 'SHA02', Arc: 'SHA01',
        Point: 'SHA02', Text: 'SHA02', Mtext: 'SHA02', Insert: 'SHA02',
        Poly: 'SHA01', Rectangle: 'SHA01', Line: 'SHA01', Marker: 'SHA02'
      };
      this.panPlan        = false;
      this.mousedownInter = 0;
      this.snapInter      = 0; // timeout limit snap
      this.snapLatLng     = 0; // last Snap position
      this.longPress      = false;
      this.modeAlert      = false;
      this.modeTree       = false;
      this.modePasteForm  = false;
      this.modeBlock      = false;
      this.typeBlock      = 'origin';
      this.layerIdEdit    = 0;
      this.drawState      = { active:false, editMode:false, dragMode: false, dragStart: false, rotation: 0, rotatioInit: 0, layerId: 0, color: '' }; // Etat drag mode dessin....
      this.selectState    = { active:false, editMode:false, dragMode: false, dragStart: false, rotation: 0, rotatioInit: 0, layerId: 0, color: '' }; // Etat drag mode selection....
      this.projection     = '';
      this.zoom           = 6;
      this.touchTolerance = 25;
      this.miniMap        = {};
      this.miniMapLayer   = new L.LayerGroup();
      this.miniDxfLayer   = {}; // Tab of LayerGroup
      this.mapCenter      = null;
      this.showmeasure    = false;
      this.timeoutHandler = null;
      this.disableClusterZoom = 18;
      this.clusterTree    = {};
    }
  
    display(latlng, zoom, projection, lraObj, drawSaved, toast) {
      latlng     = latlng || this.defautLatLng;
      zoom       = zoom   || 6;
      this.zoom  = zoom;
      projection = projection || 'EPSG3857';
      //self       = this;
      this.projection = projection;
      if( projection == 'Simple' ){
        // Hack for Circle
        L.LatLng.prototype.distanceTo = function (other) {
          var dx = other.lng - this.lng;
          var dy = other.lat - this.lat;
          return Math.sqrt(dx*dx + dy*dy);
        }
      }
      //this.baseLayer = {
      //  "SansFond" : this.setLayer('notile'),
      //  "Carte"    : ( projection == 'EPSG3857' ) ? this.setLayer('base') : this.setLayer('notile')
      //};
      var mapOptions = {
        zoom: zoom,
        zoomControl:false,
        center: latlng,
        crs: L.CRS[ projection ],
        preferCanvas: true,
        tapTolerance: 20,
        maxZoom : 28, minZoom: -20, maxNativeZoom: 16,
        layers: [this.baseLayerMap],
      };
  //    if( projection == 'EPSG3857' ){
  //      mapOptions.layers = this.baseLayer['SansFond'];//[this.baseLayerMap];
  //    }
      if( latlng !== undefined ){
        mapOptions.center = latlng
      }
      if( zoom !== undefined ){
        mapOptions.zoom = zoom
      }
    
      // add leaflet.pm controls to the map
      this.Map = new this.leaflet.map(this.mapContainer, mapOptions);
      this.Map.pm.setLang('fr');
      this.layerDraw.addTo(this.Map);
      //this.clusterTree.addTo(this.Map);

      //this.layerDraw.pm.enable( { draggable: true, snappable: true } );
      this.Map.on('pm:create', (e) => {
        // Selection pour calcul uniquement
        if( this.selectState.active ){
          setTimeout( () => {
            this.Map.removeLayer(e.layer);
          }, 2500)
          bus.$emit('selectedlist:open', e.layer.toGeoJSON() )
          return false;
        }
        this.handle += 1; // Incrément de l'ID
        e.layer.feature            = e.layer.feature || {}; // Initialize feature
        e.layer.feature.type       = e.layer.feature.type || "Feature"; // Initialize feature.type
        e.layer.feature.properties = e.layer.feature.properties || {}; // Initialize feature.properties
        e.layer.feature.geometry   = {};
        e.layer.feature.properties.id          = '0';
        e.layer.feature.properties.uid         = uuidv4();
        e.layer.feature.properties.lra_id      = this.layerTable[this.currentLayer].lra_id; //lraObj.lra_source_id;
        e.layer.feature.properties.layer       = this.currentLayer; //lraObj.lra_source_id;
        e.layer.feature.properties.handle      = this.handle.toString(16).toUpperCase();
        e.layer.feature.properties.ownerHandle = this.ownerHandle;
        e.layer.feature.properties.color       = ( this.layerTable[this.currentLayer].color ) ? this.layerTable[this.currentLayer].color : '#3388ff';
        e.layer.feature.properties.formType    = this.formTab[ e.shape ];
        e.layer.feature.properties.typ_id      = this.typTab[ e.shape ];
        e.layer.feature.geometry.type          = e.shape;
        if( e.shape == 'Circle' ){ e.layer.feature.properties.radius      = e.layer.getRadius(); }
        e.layer.on('click', () => {
          if( !this.drawState.active && this.drawSaved ){
            e.layer.feature.properties.size        = turfLength( e.layer.toGeoJSON(15) ) * 1000;
            e.layer.feature.properties.area        = turfArea( e.layer.toGeoJSON(15) );
            var prop    = JSON.stringify(e.layer.feature.properties);
            var propObj = JSON.parse( prop, e.layer._leaflet_id );
  
            bus.$emit('card:open', propObj);
          } else {
            this.setActiveLayer( e.layer );
            toast({ color: 'error', top:true, bottom:false, right:true, left:false, text: 'Enregistrer le dessin avant.' }); //alert();
          }
        });
        if( e.shape === 'Marker' ){
          e.marker.setIcon( this.buildIcon( 'blue' ) );
        } else if( e.layer.setStyle ){
          //e.layer.showMeasurements();
          e.layer.setStyle( { weight: 5 } );
        }
        if( e.layer.setStyle ){
          e.layer.setStyle( { color: e.layer.feature.properties.color } );
        }
        if( e.shape == 'Line' ){
          L.path.touchHelper(e.layer, {
            extraWeight: this.touchTolerance, extraEvent: 'click contextmenu mousedown mouseup'
          }).addTo(this.Map);
          this.layerDraw.addLayer( e.layer );
        } else {
          this.layerDraw.addLayer( e.layer );
        }
        this.setDrawSave(false);
      });
      this.Map.on('pm:drawstart', (e) => { //{ workingLayer }
        var self = this;
        //let zoom = this.Map.getZoom();
        if( L.Browser.mobile ){
          this.Map.dragging.disable(); // on fige la carte (drag)
        }
        this.drawState.dragStart = true; // on supprime la possibilité de selectionner pour modif
        if( this.Map.hasLayer(this.layerAttrib) ){
          this.Map.removeLayer( this.layerAttrib ); // on supprime les points (textes/icon)
        }
  //      this.Map.removeLayer(this.baseLayerMap);
        if( e.shape === 'Marker' ){
          e.workingLayer.setIcon( this.buildIcon( 'blue' ) );
        }
        this.setActiveLayer();
        // Gestion de la minimap
        e.workingLayer.on('pm:snap', (e) => {
          this.snapLatLng = new L.LatLng(e.snapLatLng.lat, e.snapLatLng.lng);
          if( self.snapInter > 0 ){
            clearTimeout( self.snapInter );
            self.snapInter = setTimeout( () => {
              self.snapInter = 0;
            }, 700 );
          } else {
            self.snapInter = setTimeout( () => {
              self.snapInter = 0;
            }, 700 );
          }
        });
      });
      this.Map.on('pm:drawend', () => {
        //let zoom = this.Map.getZoom();
        this.Map.dragging.enable();
        this.drawState.dragStart = false; // on rétabli la possibilité de selectionner pour modif
      });
      this.Map.on('pm:globaleditmodetoggled', (e) => {
        if( e.enabled ){
          this.setDrawSave(false);
        }
      });
      this.Map.on('pm:toggleGlobalRemovalMode', (e) => {
        if( e.enabled ){
          this.setDrawSave(false);
        }
      });
      this.Map.on('pm:remove', (e) => {
        if( e.layer.feature.properties.tree ){
          bus.$emit('tree:delete', e.layer.feature.properties.uid )
        } else {
          this.setDrawSave(false);
          this.layerDraw.eachLayer( (layer) => {
            if (layer._leaflet_id === e.layer._leaflet_id){
              this.layerDraw.removeLayer(layer)
            }
          })
        }
      });
      this.Map.on('pm:globaldragmodetoggled', (e) => {
        if( e.enabled == true ){
          this.drawState.dragStart = true;
          if( L.Browser.mobile ){
            this.Map.dragging.disable(); // on fige la carte (drag)
          }
        } else {
          this.drawState.dragStart = false;
          this.Map.dragging.enable();
        }
      });
      /************ Action sur click carte **********************/
      this.layerTree.addTo(this.Map);
      this.layerAlert.addTo(this.Map);

      this.Map.on('click', (e) => {
        if( this.modeAlert || this.modeTree ){
          this.addLocMarker( { lat: e.latlng.lat, lng: e.latlng.lng } );
        } else if ( this.modeBlock && this.modeBlock.length > 0 ){
          this.drawBlock( this.modeBlock, { lat: e.latlng.lat, lng: e.latlng.lng } );
          this.modeBlock = false;
          this.Map.pm.toggleGlobalDragMode();
        }
      });
      /**********************************************************/
  
      //this.Map.on('pm:globaleditmodetoggled', (e) => { toggleGlobalRemovalMode();
//      this.Map.on('zoomend', () => {
//        let zoom = this.Map.getZoom();
//        this.zoom = zoom;
//        if( this.zoom > this.disableClusterZoom ){ //16
//          window.clearTimeout(this.timeoutHandler);
//          // create new timeout to fire sesarch function after 500ms (or whatever you like)
//          this.timeoutHandler = window.setTimeout( () => {
//            this.updateTreeIcon();
//          }, 500);
//        }
//      });
      this.Map.on('moveend', () => { 
        let zoom = this.Map.getZoom();
        this.zoom = zoom;
        if( this.zoom >= this.disableClusterZoom ){ //16
          window.clearTimeout(this.timeoutHandler);
          // create new timeout to fire sesarch function after 500ms (or whatever you like)
          this.timeoutHandler = window.setTimeout( () => {
            this.updateTreeIcon();
          }, 200);
        }
      })

      L.control.scale( { imperial: false, position: 'topright' } ).addTo(this.Map);
      
      if( Object.prototype.toString.call( this.mapCenter ) === '[object Array]' ){
        this.Map.setView(new L.LatLng(this.mapCenter[0], this.mapCenter[1]), 20);
        const circle = L.circle([this.mapCenter[0], this.mapCenter[1]], { pmIgnore: true, color: '#00D1B2', fillColor: '#00D1B2', fillOpacity: 0.2, radius: 5 } );
        this.Map.addLayer(circle)
      }
      return this.Map;
    }
    
    // Add Draw control
    addPmControl(type){
      type = type || 'draw'
      // Leaflet PM
      var options = {
        position: 'topleft', // toolbar position, options are 'topleft', 'topright', 'bottomleft', 'bottomright'
        drawMarker: true, // adds button to draw markers
        drawPolyline: true, // adds button to draw a polyline
        drawRectangle: true, // adds button to draw a rectangle
        drawPolygon: true, // adds button to draw a polygon
        drawCircle: false, // adds button to draw a cricle
        drawCircleMarker: false,
        cutPolygon: false, // adds button to cut a hole in a polygon
        editMode: false, // adds button to toggle edit mode for all layers
        removalMode: true // adds a button to remove layers
      };
      if( type == 'draw' ){
        this.Map.pm.addControls(options);
        this.drawState.active = true;
        this.selectState.active = false;
      } else if( type == 'select' ){
        options = {
          position: 'topleft', // toolbar position, options are 'topleft', 'topright', 'bottomleft', 'bottomright'
          drawMarker: false, // adds button to draw markers
          drawPolyline: false, // adds button to draw a polyline
          drawRectangle: true, // adds button to draw a rectangle
          drawPolygon: true, // adds button to draw a polygon
          drawCircle: false, // adds button to draw a cricle
          drawCircleMarker: false,
          cutPolygon: false, // adds button to cut a hole in a polygon
          editMode: false, // adds button to toggle edit mode for all layers
          removalMode: false, // adds a button to remove layers
          dragMode: false
        };
        this.Map.pm.addControls(options);
        this.selectState.active = true;
      }
    }
  
    // Remove Draw control
    removePmControl(){
      if( this.drawState.layerId > 0 ){
        this.setActiveLayer(); // Si edition en cours on désactive
      }
      this.Map.pm.removeControls();
      if( this.Map.pm.globalDragModeEnabled() ){ this.Map.pm.toggleGlobalDragMode(); }
      if( this.Map.pm.globalRemovalEnabled() ){ this.Map.pm.toggleGlobalRemovalMode(); }
      if( this.Map.pm.globalEditEnabled() ){ this.Map.pm.disableGlobalEditMode(); }
      //map.pm.disableDraw('Polygon');
      this.selectState.active = false;
    }
  
    setLayer(value) {
      switch(value) {
        case 'base':
          return this._setBaseLayer();
        case 'offline':
          return this._setOfflineLayer();
          case 'notile':
          return this._setNoTileLayer();
        default:
          return this._setBaseLayer();
      }
    }
  
    // Set active layer (selected)
    setActiveLayer( layer ){
      if( layer == undefined && this.drawState.layerId > 0 ){
        layer = this.layerDraw._layers[ this.drawState.layerId ];
      }
      if( layer == undefined || this.layerDraw._layers[ layer._leaflet_id ] === undefined || layer.feature.geometry.type == 'Point' ){
        return false;
      }
      if( this.drawState.layerId == layer._leaflet_id ){
        this.Map._layers[ this.drawState.layerId ].pm.disable(); // désactivation édition
        if( this.layerDraw._layers[ this.drawState.layerId ].setStyle ){
          this.layerDraw._layers[ this.drawState.layerId ].setStyle( { color: this.drawState.color } );
        }
        this.drawState.editMode  = false;
        this.drawState.dragMode  = false;
        this.drawState.layerId   = 0;
      } else {
        if( this.drawState.layerId > 0 && this.Map._layers[ this.drawState.layerId ] !== undefined ){
          if( this.Map._layers[ this.drawState.layerId ].setStyle ){
            this.Map._layers[ this.drawState.layerId ].setStyle( { color: this.drawState.color } );
          }
          this.Map._layers[ this.drawState.layerId ].pm.disable();
        }
        this.drawState.layerId = layer._leaflet_id;
        this.drawState.color   = layer.options.color; //fillColor
        if( layer.setStyle ){
          layer.setStyle( { color: "red" } );
        }
        if( layer.feature.properties.formType == 'block' || layer.feature.properties.blockName !== undefined ){
          this.drawState.editMode     = true;
          this.drawState.dragMode     = true;
          this.drawState.rotation     = layer.feature.properties.rotation || 0;
          layer._rotation             = ( layer._rotation === undefined ) ? this.drawState.rotation : layer._rotation;
          this.drawState.rotationInit = layer._rotation;
        } else {
          this.drawState.editMode  = true;
          this.drawState.dragMode  = false;
          layer.pm.enable(); // activation édition
        }
        this.setDrawSave(false); // enregistrer....
      }
    }
  
    // Changer la couleur d'un calque et de ses enfant
    setLayerColor(layerGroup, color){
      if( this.layerDxf[layerGroup] !== undefined ){
        this.layerDxf[layerGroup].getLayers().forEach( (obj) => {
          if( typeof obj.setStyle === "function" ){
            obj.setStyle( { color: color } );
          }
        });
      }
    }
  
    // Afficher un Geojson
    displayGeojson( data, versionType, prv_id ){
      var dxfShapeId = this.handle;
      prv_id         = prv_id || 0;
      //var indexTab   = { "VER01": 100, "VER02": 300, "VER03": 200 };
      var self       = this;
      // Centrage
      if( data.bounds !== undefined && data.bounds.southWest !== undefined && versionType == 'VER03' ){
        var boundsObj = { 
          _southWest: L.latLng(data.bounds.southWest[1], data.bounds.southWest[0]),
          _northEast: L.latLng(data.bounds.northEast[1], data.bounds.northEast[0])
        };
        var bounds = L.latLngBounds(boundsObj._southWest, boundsObj._northEast);
        this.Map.fitBounds(bounds);
        this.panPlan = true;
        if( !this.Map.hasLayer( this.baseLayerMap ) ){
          setTimeout( () => {
            this.Map.addLayer(this.baseLayerMap);
            bus.$emit('uiobject:set', 'getMap', false )
          }, 1000); // Afficher les tuiles
        }
      }
      function onEachFeature(feature, layer) {
        layer.options.smoothFactor = 2;
        // does this feature have a property named popupContent?
        if (feature.properties && feature.properties.handle !== '' && feature.properties.formType !== 'ATTRIB' && feature.properties.formType !== 'MTEXT') {
  
            if( versionType == 'VER02' || versionType == 'VER03' || versionType == 'VER04' ){
              layer.on('click', () => {
                if( !self.drawState.active && self.drawSaved && !self.longPress ){
                  feature.properties.size = turfLength( feature ) * 1000;
                  feature.properties.area = turfArea( feature );
                  if(feature.geometry.type == 'Point' && self.modePasteForm){
                    console.log(feature.geometry.type)
                  } else {
                    if( self.selectState.active ){
                      setTimeout( () => {
                        bus.$emit('selectedlist:open', layer.toGeoJSON() )
                      }, 300);
                    } else {
                      setTimeout( () => {
                        bus.$emit('card:open', feature.properties, layer._leaflet_id, versionType );
                      }, 300);
                    }
                  }
                  //layer.showMeasurements()
                } else if( self.drawState.active && !self.drawState.dragStart ){
                  self.setActiveLayer( layer );
                }
                self.longPress = false;
              });
              layer.on('contextmenu', (e) => {
                layer.bringToBack();
                if( e.target._leaflet_id !== e.sourceTarget._leaflet_id ){
                  e.sourceTarget.bringToBack(); // cacher aussi la source (pathHelper)
                }
              });
              // init lastWeight
              layer._lastWeight = 0;
              layer.on('mousedown', (e) => {
                if( layer.options.weight !== undefined && !self.drawState.active && layer._lastWeight == 0 ){
                  layer._lastWeight = layer.options.weight;
                  if( layer.setStyle ){
                    layer.setStyle( { weight: layer.options.weight * 3 } );
                    setTimeout( () => {
                      layer.setStyle( { weight: layer._lastWeight } );
                      layer._lastWeight = 0;
                    }, 1500 );
                  }
                }
                var selfLayer = layer;
                self.mousedownInter = setTimeout( () => {
                  self.longPress = true;
                  if( selfLayer.bringToBack ){
                    selfLayer.bringToBack();
                    if( e.target._leaflet_id !== e.sourceTarget._leaflet_id ){
                      e.sourceTarget.bringToBack(); // cacher aussi la source (pathHelper)
                    }
                  }
                }, 1000 );
                setTimeout( () => { self.longPress = false; }, 1500);
              });
              layer.on('mouseup', () => {
                clearTimeout( self.mousedownInter );
              });
              //layer.showMeasurements( { showOnHover: true } );
              //layer.on( 'pm:dragstart', (e) => {
              //  self.drawState.dragStart = true;
              //});
              layer.on( 'pm:dragend', (e) => {
                layer.feature.properties.insertPoint = [ e.target.pm._tempDragCoord.lng, e.target.pm._tempDragCoord.lat ];
                self.setDrawSave(false);
                //self.drawState.dragStart = false;
              });
            }
        } else {
          layer.options.snapIgnore   = true;
          //layer.bindPopup('Aucune info');
        }
        if( feature.properties.blockName !== undefined ){
          layer.options.snapIgnore   = true;
        }
        if( parseInt(feature.properties.handle, 16) > dxfShapeId ){
          dxfShapeId = parseInt(feature.properties.handle, 16);
        }
  
      }

      var ignorePm = true;
      if( versionType == 'VER02' ){
        ignorePm = false;
      }
      var geoJ = L.geoJSON(data, {
        //snapIgnore : ignorePm,
        pmIgnore: ignorePm,
        onEachFeature: onEachFeature,
        style: function(feature) {
          var opt = {"weight":3, "fillOpacity": 0, "fillColor":"#5399CC", "opacity": 0.5, "color":"black"}; //#2D2D2D
          opt.clickTolerance = 6; // click tolerance for touche event
          if( feature.properties.color !== undefined ){
              opt.color = (feature.properties.color == '#ffffff') ? '#000000' : feature.properties.color;
              //opt.color = ( self.layerTable[feature.properties.layer] && self.layerTable[feature.properties.layer].color ) ? self.layerTable[feature.properties.layer].color : opt.color;
          }
          if( feature.properties.weight !== undefined && feature.properties.weight > 3 ){
              opt.weight  = feature.properties.weight;
              opt.lineCap = "butt";
          }
          if( feature.properties.dashArray !== undefined ){
              opt.lineCap = "butt";
              opt.dashArray = feature.properties.dashArray;
          }
          if( versionType == 'VER01' ){
            opt.fillOpacity = 0;
            opt.fillColor   = "#5399CC";
            opt.opacity     = 0.6;
            opt.color       = "#2D2D2D";
          } else { //if( versionType == 'VER02' )
            opt.fillOpacity = 0.2;
            opt.weight = 5;
            if( feature.properties.weight !== undefined ){
              opt.weight = feature.properties.weight;
            }
          }
          return opt;
        },
        pointToLayer: function (feature, latlng) {
          if( feature.properties.formType == 'ATTRIB' || feature.properties.formType == 'MTEXT' ){
            var addStyle = '';
            if( feature.properties.formType == 'MTEXT' ){
              addStyle   = ' font-size: 20px; font-weight:bold;';
            }
            var html  = '<div class="leaflet-marker-attrib" style="transform: rotate(' + feature.geometry.rotation + 'deg);'+ addStyle +'">';
            html     += '<span>' + feature.properties.text + '</span></div>'; //-HH
            var icon  = { html: html, iconSize: [50, 20],  iconAnchor: [25, 10],  popupAnchor: [0, -10], className: 'marker-transp' };
            var mySVGIcon   = L.divIcon(icon);
            if( data.layers !== undefined && data.layers[ feature.properties.name ] !== undefined && !data.layers[ feature.properties.name ].frozen && feature.properties.text.length > 0 ){
              let marker = L.marker([latlng.lat, latlng.lng], {icon: mySVGIcon, pmIgnore: true, snapIgnore : true, interactive:false }); //.bindPopup( 'coucou', { autoPan:false, closeOnClick: false } )
              marker._text = feature.properties.text;
              return marker;
            }
          } else if( feature.properties.formType == 'POINT' ){
            let marker = self.buildMarker( { type: 'tree', color: '#04c237', latLng: [ latlng.lat, latlng.lng ] } );
            //let marker = L.circle([ latlng.lat, latlng.lng ], {radius: 2, fillColor: 'red', color: 'red'});
            feature.properties.tree = true;
//            marker.on('click', () => {
//              setTimeout( () => {
//                bus.$emit('card:open', feature.properties, marker._leaflet_id, versionType );
//              }, 300);
//            })
            marker.tre_id = feature.properties.uid
            marker.on("dragend",function(e){
              var chagedPos = e.target.getLatLng();
              bus.$emit('tree:update', { tre_uid: marker.tre_id, tre_lat: chagedPos.lat, tre_lng: chagedPos.lng } );
            });
            //self.clusterTree.addLayer(marker);
            return marker;
          } else if( feature.properties.formType == 'CIRCLE' && feature.properties.formRadius !== undefined ){
//            return L.circle(latlng, { pmIgnore: ignorePm, color: 'black', fillColor: '#000', fillOpacity: 1, radius: feature.properties.formRadius }).on('click', () => {
//              bus.$emit('card:open', feature.properties, undefined, versionType );
//            });
          } /*else if( feature.properties.formType == 'INSERT' ){
            
          }*/
        }
      });
      this.handle = dxfShapeId;
      // Ajout dans le bon calque
      var prop
      for( prop in geoJ._layers ){
        if( geoJ._layers[prop].feature !== undefined && geoJ._layers[prop].feature.properties !== undefined ){
          let layName = geoJ._layers[prop].feature.properties.layer || geoJ._layers[prop].feature.properties.name;
          let geoTyp  = geoJ._layers[prop].feature.properties.formType || '';
          let TypId   = geoJ._layers[prop].feature.properties.typ_id || '';
          if( this.layerDxf[ layName ] === undefined ){
            this.layerDxf[ layName ]     = L.layerGroup();
          }
          if( geoTyp == 'ATTRIB' || geoTyp == 'MTEXT' ){
            this.layerAttrib.addLayer( geoJ._layers[prop] );
          } else {
            // spécial arbre
            if( TypId == 'SHA02' ){
              //this.layerDxf[ layName ].addLayer( geoJ._layers[prop] );
              if( !this.clusterTree[ layName ] ){
                this.clusterTree[ layName ] = L.markerClusterGroup({ disableClusteringAtZoom: this.disableClusterZoom });
              }
              this.clusterTree[ layName ].addLayer(geoJ._layers[prop]);
              if( !this.layerDxf[ layName ].hasLayer(this.clusterTree[ layName ] ) ){
                this.layerDxf[ layName ].addLayer( this.clusterTree[ layName ] )
              }
            } else {
              this.layerDxf[ layName ].addLayer( geoJ._layers[prop] );
            }
          }
          if( this.layerTable[ layName ] !== undefined && (this.layerTable[ layName ].active == undefined || this.layerTable[ layName ].active == true) ){
            this.layerDxf[ layName ].addTo(this.Map);
          } else { //if( versionType == 'VER01' )
            this.layerDxf[ layName ].addTo(this.Map);
          }
          // Add versions
          if( this.layerVersion[versionType] == undefined ){
            this.layerVersion[versionType] = L.layerGroup();
            this.layerVersion[versionType].addTo(this.Map);
          }
          //this.layerVersion[versionType].addLayer( geoJ._layers[prop] );
          //this.layerVersion[versionType][layName] = true;
        }
      }
      if( versionType == 'VER02' ){
        geoJ.eachLayer( (layer) => {
          // Fix pour les markers (ajout de la sauvegarde on.dragend)
          if( layer.feature.properties.formType == 'POINT' ){
            layer.on( 'dragend', () => {
              self.setDrawSave(false);
            });
          }
          this.layerDraw.addLayer( layer ); //this.layerDraw.bringToFront();
        });
        this.layerDrawId = prv_id;
      } else if( versionType == 'VER03' ){
        // Génération des claques
        if( data.blocks !== undefined ){
          this.blocks.origin = data.blocks;
        }
      } /*else {
        geoJ.eachLayer( (layer) => {
          this.layerVersion[versionType].addLayer( layer ); //this.layerDraw.bringToFront();
        });
      }*/
  
      if( !this.panPlan && versionType == 'VER03' ){
        this.panPlan = ( versionType == 'VER03' ) ? true : false;
        let bounds   = geoJ.getBounds();
        if( bounds.length ){
          this.Map.fitBounds(bounds);
        }
        setTimeout( () => {
          this.Map.addLayer(this.baseLayerMap);
          bus.$emit('uiobject:set', 'getMap', false )
        }, 1000); // Afficher les tuiles
      }
      if( versionType == 'VER04' ){
        //let bounds   = this.clusterTree.getBounds();
        geoJ.getBounds();
        if( bounds && this.mapCenter == null ){
          this.Map.fitBounds(bounds);
        }
        this.mapCenter = null
      }
      return true;
    }
  
    addBlock(data){
      this.blocks.generic = data.blocks;
    }
    // Passer un layer au second plan
    layerBringToFront(layer){
      layer.bringToFront();
    }
  
    // Liste des Blocks
    getBlocks( type ){
      if( this.blocks[ type ] !== undefined ){
        var blockTab = {};
        for( var prop in this.blocks[ type ] ){
          //blockTab.push( { name: prop, active: true } )
          blockTab[prop] =  { name: prop, active: true }
        }
        return blockTab;
      }
    }
  
    //retourne la liste des layers d'un group
    getLayersGroup(  ){
      var layerTab = [];
      for( var prop in this.layerDxf ){
        layerTab.push({ label: prop, active: true });
      }
      return layerTab;
    }
  
    showHideLayer( layerTab, state, versionType ){
      if( !state && this.layerDxf[layerTab] !== undefined && this.Map.hasLayer( this.layerDxf[layerTab] )) {
        this.Map.removeLayer( this.layerDxf[layerTab] );
      } else if( state && this.layerDxf[layerTab] !== undefined ){
        this.Map.addLayer( this.layerDxf[layerTab] );
        // Tolerance pour Touch device
        if( versionType !== undefined && versionType !== 'VER01' ){
          this.layerDxf[layerTab].eachLayer( (layer) => {
              if( layer.feature.geometry.type == 'LineString'  ){
                L.path.touchHelper(layer, {
                  extraWeight: this.touchTolerance, extraEvent: 'click contextmenu mousedown mouseup'
                }).addTo(this.Map);
              }
          })
        }
      }
    }
    showHideVersion( version, state ){
      if( this.layerVersion[version] !== undefined ){
        for( var prop in this.layerVersion[version] ){
          this.showHideLayer(prop, state, this.layerVersion[version]); //this.Map.removeLayer( this.layerDxf[prop] );
          //this.layerVersion[version][prop]
        }
      }
    }
  
    // Retourner le dessins (main)
    getlayerDraw(){
      if( this.drawState.layerId !== undefined && this.drawState.layerId ){
        if( this.Map._layers[ this.drawState.layerId ] !== undefined ){
          this.Map._layers[ this.drawState.layerId ].pm.disable(); // désactivation édition
          if( this.layerDraw._layers[ this.drawState.layerId ].setStyle ){
            this.layerDraw._layers[ this.drawState.layerId ].setStyle( { color: this.drawState.color } );
          }
        }
        this.drawState.editMode  = false;
        this.drawState.dragMode  = false;
        this.drawState.layerId   = 0;
      }
      if( this.Map.pm._globalEditMode ){
        this.Map.pm.toggleGlobalEditMode();
      }
      return this.layerDraw.toGeoJSON(15);
    }

    getTreeDraw(){
      //let trees = this.layerDraw.toGeoJSON(15);
      let trees = [];
      this.layerTree.eachLayer( layer => {
        trees.push(layer._latlng);
        this.layerTree.removeLayer(layer);
        this.Map.removeLayer(layer);
      })
      return trees;
    }    

    removeDxfLayer(){
      return new Promise( (resolve) => {
        for( let prop in this.layerDxf ){
          this.layerDxf[prop].eachLayer( layer => {
            this.layerDxf[prop].removeLayer(layer);
            //this.Map.removeLayer(layer);
          })
        }
        resolve('resolved')
      })
    }

    // Ajouter une zone (cercle)
    addCircleZone(pos){
      if( pos.lat == 0 || pos.lng == 0 || pos.lat == undefined ){
        pos.lat = this.defautLatLng[0];
        pos.lng = this.defautLatLng[1];
      }
      if( this.circleMarker == null || this.circleMarker == undefined ){
        this.circleMarker = L.circle([pos[1], pos[0]], {
            color: '#2196F3',
            fillColor: '#2196F3',
            fillOpacity: 0.5,
            radius: 20
        }).addTo(this.Map);
      } else {
        this.circleMarker.setLatLng( [pos[1], pos[0]] );
      }
      setTimeout( () => {
        this.Map.removeLayer(this.circleMarker)
      }, 1500);
    }
  
    panTo(pos, zoom){
      zoom = zoom || 19;
      if( pos.lat !== undefined ){
        pos[1] = pos.lat;
        pos[0] = pos.lng;
      }
      this.Map.setView( [pos[1], pos[0]], zoom, {
        pan: { animate: true, duration: 1.50 },
        zoom: { animate: true }
      });
    }
  
    // Ajout d'alerte existante
    addAlert(alertObj){
      var marker = this.buildMarker( { draggable: false, type: 'orange-warning', latLng: [alertObj.eve_lat, alertObj.eve_lng] } );
      marker.on({ 
        click: function(e) {
          bus.$emit('alert:open', { eve_id: alertObj.eve_id, eve_lat: e.latlng.lat, eve_lng: e.latlng.lng })
        }
      });
      this.layerAlert.addLayer( marker );
      this.layerAlert.addTo(this.Map);
      //this.layerAlert['alt' + alertObj.alt_id ] = marker.addTo(this.Map);
    }
    removeAllAlert(){
      this.layerAlert.eachLayer( layer => {
        this.Map.removeLayer(layer)
      })
      //this.Map.removeLayer(this.layerAlert);
    }
  
    // Ajouter un marker Alerte
    addAlertMarker(active) {
      var self = this;
      if( active ){
        this.Map.on('click', function(e) {
          self.addLocMarker(e.latlng);
        });
      } else {
        this.Map.off('click');
        if( this.markerDrag !== undefined && this.markerDrag !== null ){
          this.Map.removeLayer(this.markerDrag);
          this.markerDrag = null;
        }
      }
    }
  
    // Ajouter un marqeur de géolocalisation
    addLocMarker(pos) {
      if( pos.lat == 0 || pos.lng == 0 ){
        pos.lat = this.defautLatLng[0];
        pos.lng = this.defautLatLng[1];
      }
      if( ( this.markerDrag == null || this.markerDrag == undefined ) && !this.modeTree ){
        this.markerDrag = this.buildMarker( { draggable: true, type: 'orange-warning', latLng: [pos.lat, pos.lng] } );
        //this.markerDrag.addTo(this.Map);
        this.layerAlert.addLayer( this.markerDrag );
        var self = this;
        // Open alert on click
        if( self.modeAlert ){
          this.markerDrag.on({ 
            click: function(e) {
              bus.$emit('alert:open', { eve_id: 0, eve_lat: e.latlng.lat, eve_lng: e.latlng.lng })
            }
          });
        }
      } else if( this.modeTree ){
        this.markerDrag = this.buildMarker( { draggable: true, type: 'blue', latLng: [pos.lat, pos.lng] } );
        this.layerTree.addLayer( this.markerDrag );
        this.setDrawSave(false)
      } else {
        this.markerDrag.setLatLng( new this.leaflet.LatLng(pos.lat, pos.lng) );
      }
      //this.Map.setView( new this.leaflet.LatLng(pos.lat, pos.lng), 9 );
    }
  
    // Cloner un objet dans le layer ...
    cloneObjectToLayer( leafletId, color ){
      var cloneLayer = JSON.parse( JSON.stringify( this.Map._layers[leafletId].toGeoJSON(15) ) );
      this.handle   += 1;
      // Ajouter la couleur du Layer
      cloneLayer.properties.color = color || '64ff00';
      cloneLayer.properties.layer = this.currentLayer;
      cloneLayer.properties.handle= this.handle.toString(16).toUpperCase();
  
      var geoClone = L.geoJSON(cloneLayer); //.addTo( this.Map );
      geoClone.addTo( this.layerDraw );
      geoClone.addTo( this.layerDxf[ this.currentLayer ] );
    }
    // Déplacer un objet dans le layer ...
    moveObjectToLayer( leafletId ){
      this.Map._layers[leafletId].feature.properties.layer = this.currentLayer;
    }
  
    // Transférer un objet dans le layer ...
    switchObjectToLayer( leafletId, layerToRemove ){
      if( this.Map._layers[leafletId] !== undefined ){
        this.Map._layers[leafletId].addTo( this.layerDxf[ this.currentLayer ] );
        this.Map._layers[leafletId].feature.properties.layer = this.currentLayer;
        if( layerToRemove !== undefined && this.layerDxf[ this.currentLayer ] !== undefined ){
          this.layerDxf[ this.currentLayer ].removeLayer( this.Map._layers[leafletId] );
        }
      }
    }
  
    buildMarker(obj) {
      let title     = obj.title || '';
      let popup     = obj.popup || '';
      let draggable = obj.draggable || false
      let marker    = L.marker(obj.latLng, { //this.leaflet.marker(obj.latLng, {
        title: title,
        icon: this.buildIcon(obj),
        draggable: draggable
      });
      marker.on('dragend', () => {
        var position = marker.getLatLng();
        this.markerLoc = [ position.lat, position.lng ];
      });
      if( popup.length > 0 ){
        marker.bindPopup(popup);
      }
  
      return marker;
    }
  
    // Ajustement des attributs
    adjustAttrib(){
      this.layerAttrib.eachLayer( (marker) => {
        if( marker._parentId !== undefined && this.Map._layers[marker._parentId] !== undefined && this.Map._layers[marker._parentId].feature.properties.rotation !== undefined ){
          if( this.projection == 'EPSG3857' ){
            var bounds = this.Map._layers[ marker._parentId ].getBounds();
            if( bounds._southWest !== undefined && marker._rotation !== this.Map._layers[marker._parentId].feature.properties.rotation ){ //this.Map._layers[ marker._parentId ]._latlngs.flat(3).length > 0
              var pivot = bounds.getCenter();
              var newPoint;
              var geojObj   = marker.toGeoJSON(15);
              var rotation  = this.Map._layers[marker._parentId].feature.properties.rotation; //this.drawState.rotation - this.drawState.rotationInit;
              newPoint  = turfRotate( geojObj, rotation, { pivot: [pivot.lng, pivot.lat], mutate: false });
              newPoint  = turfFlip( newPoint );
              marker.setLatLng( newPoint.geometry.coordinates );
              var addStyle = ' font-size: 16px; font-weight:bold;';
              if( this.Map._layers[marker._parentId].feature.properties.scale !== undefined && this.Map._layers[marker._parentId].feature.properties.scale > 0 ){
                addStyle = ' font-size: ' + (16 * this.Map._layers[marker._parentId].feature.properties.scale) + 'px; font-weight:bold;';
              }
              var html  = '<div class="leaflet-marker-attrib" style="transform: rotate(' + rotation + 'deg);' + addStyle + '"><span>' + marker._text + '</span></div>';
              var mySVGIcon   = L.divIcon({ html: html, iconSize: [50, 20],  iconAnchor: [0, 0],  popupAnchor: [0, -10], className: 'marker-transp' });
              marker._rotation = rotation;
              marker.setIcon( mySVGIcon );
            }
          }
        }
      });
    }
  
    // Créer un LayerGroup
    addLayerGroup( group, layer ){
      if( this[ group ] !== undefined && this[ group ][layer] == undefined ){
        this[ group ][layer] = L.layerGroup();
      }
    }
  
    buildIcon(obj) {
      let type = obj.type;
      let html = '<div class="marker-svg marker-blue"><center></center></div>';
      let iconSize       = [34, 56];
      let iconAnchor     = [17, 55];
      let popupAnchor    = [0, -56];
      let className      = 'marker-transp';
      let metresPerPixel = 0
      let adjustZoom     = 16;
      switch(type) {
        case 'blue':
          html = this.svgIcon['blue'];
          break;
        case 'green':
          html = this.svgIcon['green'];
          break;
        case 'orange':
          html = this.svgIcon['orange'];
          break;
        case 'red':
          html = this.svgIcon['red'];
          break;
        case 'truck':
          html = this.svgIcon['truck'];
          break;
        case 'tree':
          //html       = this.svgIcon['tree'];
          if( obj.color ){
            html = '<div><svg style="fill:' + obj.color + ';" xmlns="http://www.w3.org/2000/svg" viewBox="1239.2 -915.6 332.5 593">';  
          } else {
            html = '<div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595.8 602.3">';  
          }
          html = '<div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 351.6 355.3" fill-rule="evenodd">'
          html += '<g fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round">'
          html += '<path d="m101 191 2-13 7 2 6-2-4-7-3-4-6-4v-4l2-7 9-2 4 4-4-10-5-5-2-4 7-2 6-5v-2l4 7h7l-3-7 5-6 8-7 7 9 2-4 2-7 4-2 7 2 4 2h6l-2 4 11 3 7-1 4 5-4 4h-15l-9-2-4 7-5 4-6-2h-4l6 6v4l-6 3-7 4h-4l-4 4 4 9 5 4-5 4-4 3v4l-4 4-7 5-2 2h-6l-2-2zM215 203v-8h-4v-7l4-11-4-2 2-6h-11l2-9v-6l2-9h6l3 2v-8l-1-7-2-4 5-7 2-6v-6l6-3 4 7 5 2-5 6-4 7-2 4 2 4v13l5 7-2 8-3 5 5 2-7 4-2 4 2 7 3 4 2 4-7 3-2 2zM116 197l11-2 4 4h5l2-4 2-4 4-3 4-2 3 9v6l4 2 4 3 7-3 4 3 6-1 3 7h6l2 6 5 7 2-9 2-2 6 4h13l7 2 2 5 4 4 4 2-6 2h-6l-7-2h-8l-7 2-6 2h-7l-6 1-2-7v-6l-5-5-6 3 2 4-6 4-3-6-4 4h-4l-7-2-4 2-4-2v-2l2-4 4-3h2l2-4h-4l-6-2-3-2-6-2-2-2-4-3zM168 190l-5-2 2-4h-4l-4-4v-4l4-5v-8l2-9 4-4 3 6 2 2 6-2v7l4 2 1 6v4l-3 7 1 2-5 2h-4l2 2-4 2z" stroke-width="7.498287000000001"/>'
          html += '<path d="m308 254 6-4 5 2 8-4 4-11 7-9-3-4v-11l7-2-13-4-9-4v-7l4-2h5l6 4 7 2-5-4-2-9 4-4 3-6-3-7v-4l9-7-7-6-8-9 6-6 2-9v-4l-4-2 2-6-2-5-5-4-2-6-6-3V87l-5-9-8-6 4-9-5-6-6 2v-7l-7-8-4-2-4 4-4 2-2 9 2 6 2 4-4 3-2 6-3 4-6 9 2 6-6 3h-4l-3-5 2-4v-6l-4-3h-6l-5-4 5-4 2-4h4l4 4 5-9-3-6 7-5-9 1v-7l2-9v-6l-2-6-4 2-5-5-6 7-6 4h-7l-2-8 2-7h-6l-7-4-2 7-4 2-2 6h-9l-6-6-3 6-4 2v-4h-8l2-4-3-4-4 2v6l-6 5-2-3v-4l-9 4-9-2-2-6-2-9-4-8-9 4h-6l-7-2-4 2-6 5-5 6-6 2h-9l-8 2-7 3 5 8v9l2 6 2 4 9-4 6 2 9 4 2 3-7 2-6 8 2 7-6 6 6 2-6 5h-6l-3-4 2-5-6-6 8-7-8 3-4 2-13-2v-7l-3-8v-7H48l-2 7-2 6-6-2-2 6 2 5 4 10-6 3H25l-2 4v2l6 4-2 5 11 2-4 4 2 7 4 2 3 6 6 4 4 5-4 6-4 2h-9v-10l-6-9-5-2-6-4 4-5-6-2-5 2H4v7l4 6 4 5-4 8-4 5 4 6-2 6 5 7 4 4 4 7-4 4-4 4 4 4-2 3H7v6l2 4-5 5 5 2 8 2v4l5-4 6-2 2-7 5-2 4 4 6-4 2 6 3 5v6l2 9v2l-9-2-4 6-9-2 1 9-9-5-4 1 2 8 2 7 6 2 7-5 2 11h13l11 2-7 11-8 2v9l4 6h9l4 4-4 9h11l8-2 9 4 13 2v11h6l3 4 2 4 6-2-4 9-4 6h13l6 2v4l11-2 6-2 4-11 5-4 8-2 7-9 4 4 6-4 9 4 2 9-4-2h-4l-9 4-6 5-7 6-8 2 8 4 7 18 2-11 6-5 5-4v4l6-6 2 4 4-6 5 2 4-4v-5l4-4 5 2 2 2 2 2 4-4 4 2 11-2 2 2-2-6 4-5 7-2h4v4h7l6 3 4-5v-4l-6-2 2-2 2-5-4-4v-2l4-9 2-4h9l4 6 6 3 5 8 2-6 4-7 2-4 9 8 4-8 2-9-4-2-11-4 2-9 7-6z" stroke-width="8.989659"/>'
          html += '</g>'
          html += '<path d="M164 338c-3-7-10-7 0-11 7-9 20-12 28-12 1-9-8-17-16-13-5 4-10-7-14 5-11 2-14 14-21 20-9 6-21-5-27-5 4-6 8-16-3-13-3-8-12-5-10-17-10 1-18-8-27-5-10 3-12 1-10-7-2-8-11-2-14-7-8-13 14-11 13-25-7-7-25 4-25-9-1-11-14 6-16-6-9-17 15 6 11-11 8 3 12-7 21-5-2-9-2-36-16-27-9-10-10 13-21 8-11 1-7-10-8-15 14 0 0-11 11-16 1-10-16-17-11-28-3-8 13-18-2-26-7-7 17-10 11-2 9 5 19 13 16 24 14 11 31-13 10-19-2-7-12-11-4-18-10-1-10-8-14-13 5-3 25 0 15-13-4-6-4-14 4-10 3-7 3-15 13-13 11 0-2 28 17 23 8-2 12 7 11 13 1 6 9 6 13 3 10-3 0-6 2-9 4-7 2-18 14-20-3-7-16-14-23-10-10 4-5-17-10-23 16-1 29-5 40-17 7 4 19-4 23 0 5 7 2 22 13 22 6 3 11-6 15 3 7-11 16-2 22-2 5-11 9 3 17-1 7-5 11-18 20-9-5 11 8 18 16 10 8-13 21 1 14 13-2 7 10 26-5 20-12 2-13 21 1 18 6 4-2 23 14 15 8-2 0-13 9-18 4-9 11-14 4-24 0-16 20-10 17 3 8-1 13 4 7 13 7 5 14 12 13 22 0 9 12 12 15 23-3 9 7 18-6 26-3 7 17 13 11 18-11 6 3 20-9 27 8 13-20-4-15 13 1 9 22 5 15 18 6 10-5 16-7 26-3 7-12 2-16 5-3-8-13 0-14 8-4 12 25 8 10 24-4 0-13-12-15 2-5 13-21-23-30-3-8 8 1 16-4 24 16 6-9 10-9 1-15-4-13 17-27 12-6 1-9 3-16-3-6 6-7 12-15 13-5 2-19 4-24 14-1 3-1-6-2-4zm35-103c11-7 29 7 35-5-9-10-19-16-32-14-9-12-12 14-15-4-8-2-10-10-21-11-5 3-14 2-14-4 5-15-12-14-16-4-1 9-20-7-21 6 4 8 25 11 24 17-10 3-4 17 4 12 8 1 16-2 20 3 7-1 7-17 11-5-2 14 13 13 22 10l3-1zm20-33c11-4 12-11 5-20-2-7 13-10 5-15 3-7 4-13-2-18-1-13 0-24 9-35-5-8-21-14-20 0-6 10-6 17-4 28-11 0-10 17-12 28 12-1 13 9 9 19-1 7 5 10 6 16l1-1 3-2zm-114-10c8 0 17 3 23-6 5-10 21-16 7-26-5-11 30-8 17-22 11 3 13-15 26-8 6 2 21-1 14-10-3-6-19 1-16-7-7-2-18-9-26-3-1 8-3 9-9 1-10 2-13 17-16 19-4-11-16 3-19 6 4 7 12 14-2 14-13 12 10 20 7 27-12-10-17 21-6 15zm68-1c13-4 15-24 7-32 1-10-12 1-12-11-13 4-5 22-13 30 2 6 6 11 11 14 3 1 5 0 7-1z" fill="' + obj.color + '"/>'
          html += '<path d="M167 188v-5l-3-1-4-2c-2-2-2-3 1-6l2-2v-5c0-5 1-11 3-13l1 2 3 3h6v2c0 2 1 3 3 4l2 1v4l-2 8-1 4-4 2c-3 0-4 1-4 3l-1 1h-2zM112 188c-2-2-2-2-5-1l-3 1 1-7 2 1 6-1c4-1 5-2 5-3l-2-4-4-6-4-4c-3-2-3-2-3-4l1-4 4-3 3-1 2 2c2 2 4 3 5 1l-3-9c-2-3-2-5-4-6l-3-4 3-1 4-2 3-2 1 1 2 2 4 1 5-1v-4l-1-3 2-3c1-3 7-8 8-7l3 3 4 4 5-8 2-4h4l5 2 4 1h2v4l14 3 5 1 1 1-1 1-8 1-11-1c-6-1-6-1-9 3l-4 5-1 1h-3c-5-2-9-1-9 1l3 4 3 4-3 2-6 3c-2 2-3 2-5 2s-2 0-5 3l-2 3 6 12 2 1-1 1-4 3c-3 1-3 2-3 4s0 3-2 4l-5 4-4 3h-6zM217 196c0-2-1-4-3-4l-1-2 2-7c2-6 2-7 0-8l-2-1 1-2v-5h-5l-4-1v-3l1-5 1-7v-4h3l2 1 4 1 1-9-1-10-1-2 2-3 4-12 1-3 2-1 2 3 3 3 1 1a316 316 0 0 1-9 16l1 3 1 8v7l2 3 2 3-1 4-2 6c-1 3-1 3 1 4l1 1-2 1c-2 2-5 5-5 7l4 11 1 3-2 1-3 2-2 2zM180 231l-1-5v-4l-3-3c-3-3-3-3-8-1-4 1-5 2-4 4l1 2-1 1c-1 1-2 1-2-1-1-3-2-4-4-3l-2 2c-2 2-4 2-8 1l-4-1-2 1h-3v-2l2-3 3-1 3-3 1-3c0-1-1-2-4-2s-6-1-8-3l-5-2-4-2-3-2c-4-2-4-3-1-3l3-1 2 2c2 2 3 2 5 2 4 0 4 0 7-5 1-4 1-4 4-5l3-2 1 4v6l1 4 10 5 4-1 4-1 5 2h3l1 2c1 4 1 4 5 4h3v2l4 7 3 4c2 0 2-1 3-5l2-5 3 2 3 2 6-1 9 1 4 2 3 5c3 2 3 3 2 3l-9-1-8-1-11 2-12 2h-6z" fill="' + obj.color + '"/>'
          html += '</svg>'
          html += '</div>'
          //this.svgIcon['tree'] = html
          adjustZoom = ( this.zoom < 16 ) ? 17 : this.zoom;
          metresPerPixel = 40075016.686 * Math.abs(Math.cos(this.Map.getCenter().lat * Math.PI/180)) / Math.pow(2, adjustZoom + 8);
          iconSize   = [(4 / metresPerPixel), (4 / metresPerPixel)];
          iconAnchor = [((4 / metresPerPixel) / 2), ((4 / metresPerPixel) / 2)];
          break;
        default:
          var p = type.split("-");
          if( p[1] !== undefined ){
            html= '<div class="marker-svg marker-' + p[0] + '"><img src="./' + p[1] + '.svg" style="width: 25px; margin: 0 auto; position:absolute; top:4px; right:6px;"></div>';
          } else {
            html = this.svgIcon['blue'];
          }
      }
      return this.leaflet.divIcon({ 
        html       : html, 
        iconSize   : iconSize,
        iconAnchor : iconAnchor,
        popupAnchor: popupAnchor,
        className  : className,
      });
    }

    updateTreeIcon(){
      //let metresPerPixel = 40075016.686 * Math.abs(Math.cos(this.Map.getCenter().lat * Math.PI/180)) / Math.pow(2, this.zoom + 8);
      let viewPort = this.Map.getBounds();

      for(const key in this.clusterTree){
        this.clusterTree[key].eachLayer( (layer) => {
          if( viewPort.contains(layer.getLatLng()) ){
            //let size   = 4 / metresPerPixel
            //let anchor = size / 2
            //let iconSize   = [size, size];
            //let iconAnchor = [anchor, anchor];
            let inDay = 40
            if( layer.feature?.properties?.eve_date_start ){
              let today = new Date();
              let date_to_reply = new Date(layer.feature.properties.eve_date_start);
              let timeinmilisec = date_to_reply.getTime() - today.getTime();
              inDay = Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24))
            }
            let color = '#04c237'
            color     = (inDay < 29) ? 'orange' : color
            color     = (inDay < 6) ? 'red' : color
            layer.setIcon( this.buildIcon({ type: 'tree', color: color }) )
            //layer.setIcon(
            //  this.leaflet.divIcon({ 
            //    html       : this.svgIcon['tree'], 
            //    iconSize   : iconSize,
            //    iconAnchor : iconAnchor,
            //    //className  : 'marker-transp',
            //  })
            //);
          }
        })
      }
    }

    removeTreeIcon(uid){
      return new Promise( (resolve) => {
        for(const key in this.clusterTree){
          this.clusterTree[key].eachLayer( (marker) => {
            if(marker.tre_id == uid || uid == 'all'){
              this.clusterTree[ key ].removeLayer(marker)
            }
          })
        }
        resolve('resolved')
      })
    }

    draggableTreeIcon(mode){
      if( mode ){
        this.Map.pm.enableGlobalDragMode();        
      } else {
        this.Map.pm.disableGlobalDragMode(); 
      }

      this.clusterTree.eachLayer( (layer) => {
        if( mode && layer instanceof L.Marker ){ 
          //layer.options.draggable = true
          layer.on('dragend', (e) => {
            let coor = e.target.getLatLng()
            bus.$emit('tree:update', { tre_uid: layer.tre_id, tre_lat: coor.lat, tre_lng: coor.lng })
          })
        } else if( layer instanceof L.Marker ) {
          layer.options.draggable = false
        }
      })
    }

    updateMap() {
      let bounds = new this.leaflet.LatLngBounds(this.arrayOfLatLngs);
      this.Map.fitBounds(bounds);
    }
  
    locateMe(markerState, mode){
      mode        = mode || false
      markerState = markerState || false;
      var self = this;
  
        if( markerState === true ){
          this.Map.locate({setView: true, watch: false, enableHighAccuracy: true}).on( 'locationfound', function(e){
            self.focusOnUser(e);
            let marker = self.buildMarker( { draggable: false, type: 'blue', latLng: [e.latlng.lat, e.latlng.lng] } )
            self.handle += 1;
            marker.feature            = {}; // Initialize feature
            marker.feature.type       = "Feature"; // Initialize feature.type
            marker.feature.properties = {}; // Initialize feature.properties
            marker.feature.geometry   = {};
            marker.feature.properties.id          = '0';
            marker.feature.properties.layer       = self.currentLayer; //lraObj.lra_source_id;
            marker.feature.properties.handle      = self.handle.toString(16).toUpperCase();
            marker.feature.properties.ownerHandle = self.ownerHandle;
            marker.feature.properties.color       = ( self.layerTable[self.currentLayer].color ) ? self.layerTable[self.currentLayer].color : '#3388ff';
            marker.feature.properties.formType    = self.formTab[ 'Point' ];
            marker.feature.geometry.type          = 'Point';
            
            self.layerDraw.addLayer( marker );
            //self.drawState.active = true;
            self.setDrawSave(false);
          });
        } else if(mode) {
          this.Map.locate({setView: false, watch: true, enableHighAccuracy: true}).on( 'locationfound', function(e){
            if( parseInt(e.accuracy) > 500 ){
              self.focusOnUser( { latlng: { lat: 48.818854, lng: 2.319438 } }, 15 );
            } else {
              self.focusOnUser(e);
            }
          });
        } else {
          this.Map.stopLocate();
        }
    }
    activeAlert( mode ){
      mode = mode || false
      this.modeAlert = mode;
    }
    activeTree( mode ){
      mode = mode || false
      this.modeTree = mode;
    }
    activePasteForm(mode){
      mode = mode || false
      this.modePasteForm = mode;
    }
    // Insertion de block
    insertBlock( type, block ){
      this.modeBlock = block;
      this.typeBlock = type;
    }
    // Dessiner le block
    drawBlock(  ){ //block, position
      return false;
    }
  
    focusOnUser(e, zoom) {
      zoom = zoom || 18;
      const circle = L.circle(e.latlng, { pmIgnore: true, color: '#00D1B2', fillColor: '#00D1B2', fillOpacity: 0.2, radius: 5 } );
      // adjusts zoom and position
      this.Map.panTo( L.latLng(e.latlng.lat, e.latlng.lng) );
      setTimeout( () => {
        this.Map.setZoom(zoom);
      }, 2000);
      // add to map
      circle.addTo(this.Map);
      setTimeout( () => {
        this.Map.removeLayer(circle); // clear map
      }, 25000);
    }
  
    startCreateMarker(){
      this.Map.pm.enableDraw('Marker', { });
    }
  
    disableDraw(){
      this.Map.pm.disableDraw();
    }
  
    setMap(value) {
      switch(value) {
        case 'offline':
          this.Map.removeLayer(this.baseLayerMap);
          this.Map.addLayer(this.offlineLayerMap);
        break;
  
        case 'online':
          this.Map.removeLayer(this.offlineLayerMap);
          this.Map.addLayer(this.baseLayerMap);
  
          this.enableMap();
        break;
      }
    }
  
      // Calcul de distance GPS (direct)
    distance = function(lat_a, lon_a, lat_b, lon_b) {
      let a = Math.PI / 180;
      let lat1 = lat_a * a;
      let lat2 = lat_b * a;
      let lon1 = lon_a * a;
      let lon2 = lon_b * a;
  
      let t1 = Math.sin(lat1) * Math.sin(lat2);
      let t2 = Math.cos(lat1) * Math.cos(lat2);
      let t3 = Math.cos(lon1 - lon2);
      let t4 = t2 * t3;
      let t5 = t1 + t4;
      let rad_dist = Math.atan(-t5/Math.sqrt(-t5 * t5 +1)) + 2 * Math.atan(1);
  
      return Math.round( ((rad_dist * 3437.74677 * 1.1508) * 1.6093470878864446) * 100 ) / 100 ;
    }
  
    enableMap() {
      this.Map.dragging.enable();
      this.Map.touchZoom.enable();
      this.Map.doubleClickZoom.enable();
      this.Map.scrollWheelZoom.enable();
      this.Map.boxZoom.enable();
      this.Map.keyboard.enable();
  
      if (this.Map.tap) {
        this.Map.tap.enable();
      }
    }
  
    _setBaseLayer() {
      //return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', { //this.layerUrl
      return L.tileLayer('https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/osmtiles/webmercator/{z}/{x}/{y}.png', { //this.layerUrl
        attribution: 'TECHNOSIG',
        maxNativeZoom: 18, maxZoom : 24,
        id: 'osm'
      });
    }
    _setOfflineLayer() {
      return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', { //this.layerUrl
        attribution: 'TECHNOSIG - Offline',
        id: 'osm cache'
      });
    }
    _setNoTileLayer() {
      return L.tileLayer('', { //this.layerUrl
        attribution: 'TECHNOSIG - Offline',
        id: 'osm cache'
      });
    }
    setTileLayer( link ){
      this.Map.removeLayer(this.baseLayerMap);
      this.baseLayerMap = L.tileLayer(link, {
        attribution: 'TECHNOSIG',
        id: 'osm',
        maxZoom : 28, minZoom: -20, maxNativeZoom: 18
      });
      this.Map.addLayer(this.baseLayerMap);
    }
  
    setInitialCenter(){
      this.Map.setView(this.defautLatLng)
      this.Map.setZoom(6); //this.Map.panTo(this.defautLatLng, {animate: true, duration: 1.0});
    }
  
    removeAllLayer(){
      this.Map.eachLayer( (layer) => {
        this.Map.removeLayer(layer)
      })
    }

    showMeasures() {
      this.showmeasure = !this.showmeasure;
      this.Map.eachLayer( (layer) => {
        if (!(layer instanceof L.Marker) && layer.feature && (layer.feature.properties.formType == "LINE" || layer.feature.properties.formType == "LWPOLYLINE") && this.showmeasure) {
          layer.showMeasurements();
        } else if (!(layer instanceof L.Marker) && layer.feature && (layer.feature.properties.formType == "LINE" || layer.feature.properties.formType == "LWPOLYLINE") && !this.showmeasure) {
          layer.hideMeasurements();
        }
      });
    }
  }
  
  export default LfMap;